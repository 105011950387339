import React, { useEffect, useState } from 'react';
import { Button, TextField, FormControl, Typography } from '@mui/material';
import { updateLocation } from 'utils/apiRequests/locations';
import { Loader } from 'components/shared/Loader';
import { Airport, City, Country, Terminal } from 'types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CreateLocationFormDropdown } from './CreateLocationFormDropdown';
import { useNavigate } from 'react-router-dom';

const transformData = (inputData: any) => {
  return {
    cities: [
      {
        name: inputData.city,
        image: inputData.cityImage || '',
        city_id: inputData.cityId,
        airports: [
          {
            name: inputData.airport,
            image: inputData.airportImage || '',
            airport_id: inputData.airportId,
            terminals: [
              {
                name: inputData.terminal,
                image: inputData.terminalImage || '',
                devices: [],
                terminal_id: inputData.terminalId,
                lat: inputData.terminalLat,
                lng: inputData.terminalLng,
              },
            ],
            lat: inputData.airportLat,
            lng: inputData.airportLng,
          },
        ],
        lat: inputData.cityLat,
        lng: inputData.cityLng,
      },
    ],
    country_id: inputData.countryId,
    name: inputData.country,
    image: inputData.countryImage || '',
    lat: inputData.countryLat,
    lng: inputData.countryLng,
  };
};

interface UpdateLocationFormProps {
  locations: Country[];
  selectedLocation?: {
    countryId: number;
    cityId: number;
    airportId: number;
    terminalId: number;
  };
}

export const UpdateLocationForm: React.FC<UpdateLocationFormProps> = ({
  locations,
  selectedLocation,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [country, setCountry] = useState<string>('');
  const [countryId, setCountryId] = useState<number | null>(null);
  const [countryImage, setCountryImage] = useState<string>('');
  const [countryLat, setCountryLat] = useState<number | null>(null);
  const [countryLng, setCountryLng] = useState<number | null>(null);
  const [countryAnchorEl, setCountryAnchorEl] = useState<HTMLElement | null>(null);

  const [city, setCity] = useState<string>('');
  const [cityId, setCityId] = useState<number | null>(null);

  const [cityImage, setCityImage] = useState<string>('');
  const [cityLat, setCityLat] = useState<number | null>(null);
  const [cityLng, setCityLng] = useState<number | null>(null);
  const [cityAnchorEl, setCityAnchorEl] = useState<HTMLElement | null>(null);

  const [airport, setAirport] = useState<string>('');
  const [airportId, setAirportId] = useState<number | null>(null);

  const [airportImage, setAirportImage] = useState<string>('');
  const [airportLat, setAirportLat] = useState<number | null>(null);
  const [airportLng, setAirportLng] = useState<number | null>(null);
  const [airportAnchorEl, setAirportAnchorEl] = useState<HTMLElement | null>(null);

  const [terminal, setTerminal] = useState<string>('');
  const [terminalId, setTerminalId] = useState<number | null>(null);

  const [terminalImage, setTerminalImage] = useState<string>('');
  const [terminalLat, setTerminalLat] = useState<number | null>(null);
  const [terminalLng, setTerminalLng] = useState<number | null>(null);
  const [terminalAnchorEl, setTerminalAnchorEl] = useState<HTMLElement | null>(null);

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = transformData({
      country,
      countryId,
      countryImage,
      countryLat,
      countryLng,
      city,
      cityId,
      cityImage,
      cityLat,
      cityLng,
      airport,
      airportId,
      airportImage,
      airportLat,
      airportLng,
      terminal,
      terminalId,
      terminalImage,
      terminalLat,
      terminalLng,
    });
    console.log(data);
    setIsLoading(true);
    const resp = await updateLocation(data);
    if (resp) {
      alert('Successfully updated location!');
      navigate('/locations');
    } else {
      alert('Something went wrong when updating location');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    console.log('selectedLocation:', selectedLocation);
    if (selectedLocation) {
      const country = locations.find(
        (country) => country.country_id === selectedLocation.countryId,
      );
      if (country) {
        setCountry(country.name);
        setCountryId(country.country_id);
        setCountryImage(country.image);
        setCountryLat(country.lat);
        setCountryLng(country.lng);

        const city = country.cities.find((ct) => ct.city_id === selectedLocation.cityId) as City;

        if (city) {
          setCity(city.name);
          setCityId(city.city_id);
          setCityImage(city.image);
          setCityLat(city.lat);
          setCityLng(city.lng);

          const airport = city.airports.find(
            (arp) => arp.airport_id === selectedLocation.airportId,
          ) as Airport;

          if (airport) {
            setAirport(airport.name);
            setAirportId(airport.airport_id);

            setAirportImage(airport.image);
            setAirportLat(airport.lat);
            setAirportLng(airport.lng);

            const terminal = airport.terminals.find(
              (trm) => trm.terminal_id === selectedLocation.terminalId,
            ) as Terminal;
            if (terminal) {
              setTerminal(terminal.name);
              setTerminalId(terminal.terminal_id);

              setTerminalImage(terminal.image);
              setTerminalLat(terminal.lat);
              setTerminalLng(terminal.lng);
            }
          }
        }
      }
    }
  }, [selectedLocation, locations]);

  const isFormValid =
    country &&
    city &&
    airport &&
    terminal &&
    countryLat !== null &&
    countryLng !== null &&
    cityLat !== null &&
    cityLng !== null &&
    airportLat !== null &&
    airportLng !== null &&
    terminalLat !== null &&
    terminalLng !== null;

  return (
    <form onSubmit={handleSubmit}>
      {isLoading && <Loader />}

      <Typography variant="h6">Country:</Typography>

      <FormControl fullWidth margin="normal">
        <TextField
          required
          label="Country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <Button
                sx={{ width: '300px' }}
                size="small"
                onClick={(e) => setCountryAnchorEl(e.currentTarget)}
              >
                <Typography variant="caption">Select existing country</Typography>
                <ArrowDropDownIcon />
              </Button>
            ),
          }}
        />
        <CreateLocationFormDropdown
          locations={locations}
          anchorEl={countryAnchorEl}
          setAnchorEl={setCountryAnchorEl}
          setLocationName={setCountry}
          setLocationImg={setCountryImage}
          setLocationLat={setCountryLat}
          setLocationLng={setCountryLng}
          setLocationId={setCountryId}
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          label="Country Image URL (optional)"
          value={countryImage}
          onChange={(e) => setCountryImage(e.target.value)}
          variant="outlined"
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          required
          type="number"
          label="Country Latitude"
          value={countryLat || ''}
          onChange={(e) => setCountryLat(parseFloat(e.target.value))}
          variant="outlined"
        />
        <TextField
          sx={{ mt: '10px' }}
          required
          type="number"
          label="Country Longitude"
          value={countryLng || ''}
          onChange={(e) => setCountryLng(parseFloat(e.target.value))}
          variant="outlined"
        />
      </FormControl>

      <Typography variant="h6">City:</Typography>

      <FormControl fullWidth margin="normal">
        <TextField
          required
          label="City"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <Button
                sx={{ width: '300px' }}
                size="small"
                onClick={(e) => setCityAnchorEl(e.currentTarget)}
              >
                <Typography variant="caption">Select existing city</Typography>
                <ArrowDropDownIcon />
              </Button>
            ),
          }}
        />
        {country && (
          <CreateLocationFormDropdown
            locations={locations.find((loc) => loc.name === country)?.cities as City[]}
            anchorEl={cityAnchorEl}
            setAnchorEl={setCityAnchorEl}
            setLocationName={setCity}
            setLocationImg={setCityImage}
            setLocationLat={setCityLat}
            setLocationLng={setCityLng}
            setLocationId={setCityId}
          />
        )}
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          label="City Image URL (optional)"
          value={cityImage}
          onChange={(e) => setCityImage(e.target.value)}
          variant="outlined"
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          required
          type="number"
          label="City Latitude"
          value={cityLat || ''}
          onChange={(e) => setCityLat(parseFloat(e.target.value))}
          variant="outlined"
        />
        <TextField
          sx={{ mt: '10px' }}
          required
          type="number"
          label="City Longitude"
          value={cityLng || ''}
          onChange={(e) => setCityLng(parseFloat(e.target.value))}
          variant="outlined"
        />
      </FormControl>

      <Typography variant="h6">Airport:</Typography>

      <FormControl fullWidth margin="normal">
        <TextField
          required
          label="Airport"
          value={airport}
          onChange={(e) => setAirport(e.target.value)}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <Button
                sx={{ width: '300px' }}
                size="small"
                onClick={(e) => setAirportAnchorEl(e.currentTarget)}
              >
                <Typography variant="caption">Select existing Airport</Typography>
                <ArrowDropDownIcon />
              </Button>
            ),
          }}
        />
        {country && city && (
          <CreateLocationFormDropdown
            locations={
              locations.find((loc) => loc.name === country)?.cities.find((ci) => ci?.name === city)
                ?.airports as Airport[]
            }
            anchorEl={airportAnchorEl}
            setAnchorEl={setAirportAnchorEl}
            setLocationName={setAirport}
            setLocationImg={setAirportImage}
            setLocationLat={setAirportLat}
            setLocationLng={setAirportLng}
            setLocationId={setAirportId}
          />
        )}
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          label="Airport Image URL (optional)"
          value={airportImage}
          onChange={(e) => setAirportImage(e.target.value)}
          variant="outlined"
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          required
          type="number"
          label="Airport Latitude"
          value={airportLat || ''}
          onChange={(e) => setAirportLat(parseFloat(e.target.value))}
          variant="outlined"
        />
        <TextField
          sx={{ mt: '10px' }}
          required
          type="number"
          label="Airport Longitude"
          value={airportLng || ''}
          onChange={(e) => setAirportLng(parseFloat(e.target.value))}
          variant="outlined"
        />
      </FormControl>

      <Typography variant="h6">Terminal:</Typography>

      <FormControl fullWidth margin="normal">
        <TextField
          required
          label="Terminal"
          value={terminal}
          onChange={(e) => setTerminal(e.target.value)}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <Button
                sx={{ width: '300px' }}
                size="small"
                onClick={(e) => setTerminalAnchorEl(e.currentTarget)}
              >
                <Typography variant="caption">Select existing Terminal</Typography>
                <ArrowDropDownIcon />
              </Button>
            ),
          }}
        />
        {country && city && (
          <CreateLocationFormDropdown
            locations={
              locations
                .find((loc) => loc.name === country)
                ?.cities.find((ci) => ci?.name === city)
                ?.airports.find((air) => air.name === airport)?.terminals as Terminal[]
            }
            anchorEl={terminalAnchorEl}
            setAnchorEl={setTerminalAnchorEl}
            setLocationName={setTerminal}
            setLocationImg={setTerminalImage}
            setLocationLat={setTerminalLat}
            setLocationLng={setTerminalLng}
            setLocationId={setTerminalId}
          />
        )}
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          label="Terminal Image URL (optional)"
          value={terminalImage}
          onChange={(e) => setTerminalImage(e.target.value)}
          variant="outlined"
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          required
          type="number"
          label="Terminal Latitude"
          value={terminalLat || ''}
          onChange={(e) => setTerminalLat(parseFloat(e.target.value))}
          variant="outlined"
        />
        <TextField
          sx={{ mt: '10px' }}
          required
          type="number"
          label="Terminal Longitude"
          value={terminalLng || ''}
          onChange={(e) => setTerminalLng(parseFloat(e.target.value))}
          variant="outlined"
        />
      </FormControl>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isFormValid || isLoading}
      >
        Save
      </Button>
    </form>
  );
};
