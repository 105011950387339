import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { TextField, Button } from '@mui/material/';
import { config } from 'config';

const ForgotPassword = (): JSX.Element => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [emailSent, setEmailSent] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsDisabled(true);

      const res = await fetch(`${config.endpointUrl}/recoverPasswordEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({ email }),
      });
      const data = await res.json();

      if (res.status !== 200) {
        throw new Error(data.error);
      }

      console.log(data);
      setEmailSent(true);
    } catch (err: any) {
      console.log(err);
      setError(true);
      setErrorMessage(err.message);
      setIsDisabled(false);
    }
  };

  return (
    <div className="App">
      {emailSent ? (
        <div>
          An email has been sent to {email} with instructions on how to reset your password.
        </div>
      ) : (
        <div>
          <form onSubmit={onSubmit} className="forgot-password-wrapper form-wrapper">
            <h1 className="form-title">Forgot Password</h1>
            <p className="form-description">
              Enter your email address and we'll send you a link to reset your password.
            </p>
            <div>
              <TextField
                className="email-input"
                label="Email"
                variant="filled"
                value={email}
                error={error}
                helperText={errorMessage}
                onInput={(e: any) => setEmail(e.target.value)}
                disabled={isDisabled}
              />
            </div>
            <div className="button-wrapper">
              <Button variant="outlined" onClick={() => navigate('/login')} disabled={isDisabled}>
                Back
              </Button>
              <Button variant="contained" type="submit" disabled={isDisabled}>
                Send
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
