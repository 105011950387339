import { Box, Typography } from '@mui/material';
import { CreateLocationForm, Loader, Navbar } from 'components';
import { config } from 'config';
import React, { useEffect, useState } from 'react';
import { Country } from 'types';
import { getLocations } from 'utils/apiRequests/locations';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';

export const CreateLocation = () => {
  const [locations, setLocations] = useState<Country[] | null>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getLocationsHelper = async () => {
    setIsLoading(true);
    const resp = await getLocations();
    if (resp) {
      setLocations(resp);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getLocationsHelper();
  }, []);

  return (
    <Navbar>
      <div
        style={{
          padding: '20px',
          width: '100%',
          height: '1700px',
          backgroundColor: config.colors.background,
        }}
      >
        {isLoading && <Loader />}
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" component={RouterLink} to="/locations">
            Locations
          </Link>
          <Typography color="textPrimary"> Create Location</Typography>
        </Breadcrumbs>
        <Box display="flex" alignItems="center" justifyContent="center" overflow="scroll" py={'5%'}>
          {locations && <CreateLocationForm locations={locations} />}
        </Box>
      </div>
    </Navbar>
  );
};
