import { Box, Breadcrumbs, Button, Typography } from '@mui/material';
import { EditDeviceForm, Loader, Navbar } from 'components';
import { config } from 'config';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Device, WebApp } from 'types';
import { getADevice, getWebApps } from 'utils/apiRequests';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export const EditDevice = () => {
  const [device, setDevice] = useState<Device | null>(null);
  const [webApps, setWebApps] = useState<WebApp[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const { id } = useParams();

  const getData = async () => {
    if (!id) return;
    setIsLoading(true);
    const deviceResp = await getADevice(id);
    const webAppsResp = await getWebApps();

    if (deviceResp) {
      setDevice(deviceResp);
    }
    if (webAppsResp) {
      setWebApps(webAppsResp);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [id]);

  return (
    <Navbar>
      <div
        style={{
          padding: '20px',
          width: '100%',
          height: '1700px',
          backgroundColor: config.colors.background,
        }}
      >
        {isLoading && <Loader />}
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Button variant="text" color="inherit" onClick={() => navigate(-1)}>
            {'<-'} Back
          </Button>
        </Breadcrumbs>
        <Box display="flex" alignItems="center" justifyContent="center" overflow="scroll" py={'5%'}>
          {device ? (
            <EditDeviceForm device={device} webApps={webApps} />
          ) : (
            <Typography>No device found</Typography>
          )}
        </Box>
      </div>
    </Navbar>
  );
};
