import axios from 'axios';
import { config } from 'config';
import { WebApp } from 'types';

export const getWebApps = async () => {
  try {
    const resp = await axios.post(`${config.monitoringIngestionUrl}/get_webapps`);
    if (resp) {
      return resp.data as WebApp[];
    }
  } catch (error) {
    return null;
  }
};
