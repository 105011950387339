import { useEffect, useState, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button } from '@mui/material/';
import { config } from 'config';

export const RecoverPasswordLink = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams();
  const token = useMemo(() => params.token, [params]);

  const [validToken, setValidToken] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    const checkRecoverPasswordToken = async () => {
      try {
        const res = await fetch(`${config.endpointUrl}/checkRecoverPasswordToken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({ token }),
        });
        if (res.status !== 200) throw new Error('Invalid token');
        setValidToken(true);
      } catch (err: any) {
        console.log(err);
        navigate('/404');
      }
    };
    checkRecoverPasswordToken();
  }, [token]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsDisabled(true);
      const res = await fetch(`${config.endpointUrl}/resetPasswordEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({ token, password: newPassword, confirmPassword }),
      });
      const data = await res.json();

      if (res.status !== 200) {
        throw new Error(data.error);
      }
      console.log(data);
      navigate('/login');
    } catch (err: any) {
      console.log(err);
      setError(true);
      setErrorMessage(err.message);
      setIsDisabled(false);
    }
  };

  return (
    <div className="App">
      {validToken && (
        <form onSubmit={onSubmit} className="reset-password-wrapper form-wrapper">
          <h1 className="form-title">Reset Password</h1>
          <p className="form-description">Enter your new password twice to reset your account</p>
          <div>
            <TextField
              className="new-password-input"
              type="password"
              label="New Password"
              variant="filled"
              value={newPassword}
              onInput={(e: any) => setNewPassword(e.target.value)}
              error={error}
              helperText={errorMessage}
              disabled={isDisabled}
            />
          </div>
          <div>
            <TextField
              className="confirm-password-input"
              type="password"
              label="Confirm Password"
              variant="filled"
              value={confirmPassword}
              onInput={(e: any) => setConfirmPassword(e.target.value)}
              error={error}
              disabled={isDisabled}
            />
          </div>
          <div className="button-wrapper">
            <Button type="submit" variant="contained" disabled={isDisabled}>
              Reset Password
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};
