import { Button, TextField } from '@mui/material';
import { FileUpload } from 'components';

type IClientsForm = {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  clientName: string;
  setClientName: (clientName: string) => void;
  uploadedImage: string;
  setUploadedImage: (uploadedImage: string) => void;
  imageUrl?: string;
  setImageUrl?: (imageUrl: string) => void;
  isDisabled: boolean;
  setNoImage: (noImage: boolean) => void;
  submitButtonText: string;
};

export const ClientsForm = (props: IClientsForm): JSX.Element => {
  return (
    <form className="data-form" onSubmit={props.onSubmit}>
      <TextField
        className="client-name-input input"
        label="Client Name"
        variant="filled"
        value={props.clientName}
        // error={error}
        // helperText={errorMessage}
        onInput={(e: any) => props.setClientName(e.target.value)}
        disabled={props.isDisabled}
      />
      Client Logo:
      <div className="image-upload">
        <FileUpload
          onChange={props.setUploadedImage}
          disabled={props.isDisabled}
          imageUrl={props.imageUrl}
          setImageUrl={props.setImageUrl}
          setNoImage={props.setNoImage}
          description="Drag and drop or click here to upload a logo for the client"
        />
      </div>
      <div className="button-wrapper">
        <Button variant="contained" type="submit" disabled={props.isDisabled}>
          {props.submitButtonText}
        </Button>
      </div>
    </form>
  );
};
