import axios from 'axios';
import { config } from 'config';
import { Country, Device } from 'types';

export const getLocations = async () => {
  try {
    const resp = await axios.post(`${config.monitoringIngestionUrl}/get_location`);
    if (resp) {
      return resp.data as Country[];
    }
  } catch (error) {
    return null;
  }
};

export const addLocation = async (location: Country) => {
  try {
    await axios.post(`${config.monitoringIngestionUrl}/add_location`, {
      ...location,
    });
    return true;
  } catch (error) {
    return null;
  }
};
export const updateLocation = async (location: Country) => {
  try {
    await axios.post(`${config.monitoringIngestionUrl}/udpate_location`, {
      ...location,
    });
    return true;
  } catch (error) {
    return null;
  }
};

export const getDevices = async (countryId?: string) => {
  try {
    let body = {};
    if (countryId) {
      body = { countryId };
    }
    const resp = await axios.post(`${config.monitoringIngestionUrl}/get_device`, body);
    if (resp) {
      return resp.data as Device[];
    }
  } catch (error) {
    return null;
  }
};
