import { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  Alert,
  Typography,
  Box,
  Divider,
} from '@mui/material';

import { UpdateEmailModal, ChangePasswordModal, Navbar, Loader } from 'components';

import { useAuth } from 'context';
import { config } from 'config';

const Profile = () => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [accessRights, setAccessRights] = useState<string>('');

  const [openUpdateEmail, setOpenUpdateEmail] = useState<boolean>(false);
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);

  const [updateEmailSuccess, setUpdateEmailSuccess] = useState<boolean>(false);
  const [updatePasswordSuccess, setUpdatePasswordSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (!user) return;
    setEmail(user.email);
    setFirstName(user.firstName);
    setSurname(user.surname);
    setAccessRights(user?.accessRights?.management || '');
  }, [user]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const accessToken = localStorage.getItem('accessToken');
      const userId = localStorage.getItem('userId');
      if (!accessToken) throw new Error('No user token');
      setIsLoading(true);
      const res = await fetch(`${config.endpointUrl}/updateUserConfig`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          accessToken,
          userId,
          email,
          firstName,
          surname,
          accessRights,
        }),
      });
      const data = await res.json();
      if (res.status !== 200) throw new Error(data.error);
      setError(false);
      setSuccess(true);
      setIsLoading(false);
    } catch (err: any) {
      console.log(err);
      setError(true);
      setSuccess(false);
      setIsLoading(false);
    }
  };

  return (
    <Navbar>
      <div
        style={{
          padding: '20px',
          width: '100%',
          height: '100%',
          backgroundColor: config.colors.background,
        }}
      >
        {isLoading && <Loader />}

        <Box pt={5} display="flex" flexDirection="column">
          <Typography variant="h5">Profile Settings</Typography>
          <Typography>View and manage your users settings</Typography>

          <Divider sx={{ mt: 2 }} />
        </Box>

        <Box width="100%" display="flex" alignItems="center" justifyContent="center" height="80%">
          {error && <Alert severity="error">Account settings update failure</Alert>}
          {success && <Alert severity="success">Account settings updated successfully</Alert>}
          {updateEmailSuccess && (
            <Alert severity="success">
              Email information is updated. We will send you an email to confirm the change
            </Alert>
          )}
          {updatePasswordSuccess && (
            <Alert severity="success">
              Password updated successfully. Please login with your new password
            </Alert>
          )}

          <form className="account-settings-form" onSubmit={onSubmit}>
            <Stack spacing={1} className="email-wrapper">
              Email: {email}
            </Stack>
            <div className="update-email-button-wrapper">
              <Button
                className="update-email-button"
                variant="contained"
                color="error"
                onClick={() => setOpenUpdateEmail(true)}
              >
                Update Email
              </Button>
              <Button variant="contained" color="error" onClick={() => setOpenChangePassword(true)}>
                Change Password
              </Button>
            </div>

            <TextField
              className="first-name-input input"
              label="First Name"
              variant="filled"
              value={firstName}
              // error={error}
              // helperText={errorMessage}
              onInput={(e: any) => setFirstName(e.target.value)}
              disabled={isLoading}
            />
            <TextField
              className="surname-input input"
              label="Surname"
              variant="filled"
              value={surname}
              // error={error}
              // helperText={errorMessage}
              onInput={(e: any) => setSurname(e.target.value)}
              disabled={isLoading}
            />
            <RadioGroup row>
              {user?.accessRights?.management === '111' && (
                <FormControlLabel
                  value="111"
                  control={
                    <Radio
                      checked={accessRights === '111'}
                      onChange={(e: any) => setAccessRights(e.target.value)}
                    />
                  }
                  label="Super User"
                />
              )}
              {(user?.accessRights?.management === '111' ||
                user?.accessRights?.management === '11') && (
                <FormControlLabel
                  value="11"
                  control={
                    <Radio
                      checked={accessRights === '11'}
                      onChange={(e: any) => setAccessRights(e.target.value)}
                    />
                  }
                  label="Admin"
                />
              )}
              <FormControlLabel
                value="0"
                control={
                  <Radio
                    checked={accessRights === '0'}
                    onChange={(e: any) => setAccessRights(e.target.value)}
                  />
                }
                label="User"
              />
            </RadioGroup>
            <div className="button-wrapper">
              <Button variant="contained" type="submit" disabled={isLoading}>
                Send
              </Button>
            </div>
          </form>
          {openUpdateEmail && user && (
            <UpdateEmailModal
              existingEmail={user.email}
              openUpdateEmail={openUpdateEmail}
              setOpenUpdateEmail={setOpenUpdateEmail}
              setUpdateEmailSuccess={setUpdateEmailSuccess}
            />
          )}
          {openChangePassword && (
            <ChangePasswordModal
              openChangePassword={openChangePassword}
              setOpenChangePassword={setOpenChangePassword}
              setUpdatePasswordSuccess={setUpdatePasswordSuccess}
            />
          )}
        </Box>
      </div>
    </Navbar>
  );
};
export default Profile;
