import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { StatusCard } from './StatusCard';
import { config } from 'config';
import { Device, DeviceStatus as DeviceStatusType } from 'types';
import { useNavigate } from 'react-router-dom';
import { timeAgo } from 'utils/utils';

interface DeviceStatusProps {
  deviceStatus: DeviceStatusType[] | null;
  devices: Device[] | null;
  lastFetch: number | null;
}

export const DeviceStatus: React.FC<DeviceStatusProps> = ({ deviceStatus, devices, lastFetch }) => {
  const navigate = useNavigate();
  return (
    <Box display="flex" flexDirection="column" width="90%" marginRight="10%" paddingTop="20px">
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5" fontWeight={500} color={config.colors.darkGray}>
          Device Status
        </Typography>

        <Button>
          <FilterAltIcon />
          <Typography variant="button" color={config.colors.primaryColor}>
            Filters
          </Typography>
        </Button>
      </Box>
      <Divider style={{ margin: '10px 0' }} />

      <Box display="flex" flexDirection="column" justifyContent="space-between" marginTop="20px">
        {deviceStatus ? (
          <>
            <StatusCard
              onClick={() => navigate('/location-devices?id=active')}
              number={
                deviceStatus.filter(
                  (dev) => dev.active && devices?.find((dvc) => dvc.device_id === dev.deviceId),
                ).length
              }
              status="Active Deployments"
              lastUpdated={lastFetch ? timeAgo(lastFetch) : 'Unknown'}
            />
            <StatusCard
              onClick={() => navigate('/location-devices?id=offline')}
              number={
                deviceStatus.filter(
                  (dev) => !dev.active && devices?.find((dvc) => dvc.device_id === dev.deviceId),
                ).length
              }
              status="Offline Deployments"
              lastUpdated={lastFetch ? timeAgo(lastFetch) : 'Unknown'}
            />
          </>
        ) : (
          <Typography>Could not find device status</Typography>
        )}
      </Box>
    </Box>
  );
};
