export const config = {
  endpointUrl: 'https://sc7gl4sxb0.execute-api.eu-west-1.amazonaws.com',
  dataEndpointUrl: 'https://74u4f8mxh2.execute-api.eu-west-1.amazonaws.com',
  monitoringIngestionUrl: 'https://98qlmk6j8i.execute-api.eu-west-1.amazonaws.com',
  clientsList: [
    {
      brand: 'Gucci',
      project: 'Gucci VTO',
      clientID: 'COTY_BR04',
      projectID: 'GUCCI_MON01',
    },
    {
      brand: 'Coty',
      project: 'Burberry',
      clientID: 'COTY_BR04',
      projectID: 'BBRRY_MON01',
    },
  ],
  locationsList: [
    {
      name: 'Play Office',
      lat: 51.526268,
      long: -0.07934,
      image: './imgs/location-play-london.jpg',
    },
    {
      name: 'Coty Office',
      lat: 46.2790405,
      long: 6.1637417,
      image: './imgs/location-coty-geneva.jpg',
    },
    {
      name: 'TFWA Cannes',
      lat: 43.55104413793302,
      long: 7.017907830691333,
      image: './imgs/location-tfwa-cannes.jpg',
    },
  ],
  colors: {
    primaryColor: 'rgb(75, 150, 214)',
    lightGray: 'rgb(233,233,233)',
    darkGray: 'rgb(117,117,117)',
    orange: 'rgb(240,151,55)',
    green: 'rgb(109, 209, 96)',
    blue: 'rgb(88,174,247)',
    background: 'rgb(250,250,250)',
  },
};
