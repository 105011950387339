import React from 'react';
import { Box, Typography, Divider, IconButton } from '@mui/material';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { config } from 'config';
import { Device, Alert } from 'types';
import { formatEventType, timeAgo } from 'utils/utils';
import { useNavigate } from 'react-router-dom';

interface NotificationProps {
  alert: Alert;
  device?: Device;
}

export const Notification: React.FC<NotificationProps> = ({ alert, device }) => {
  const offlineDeployments = ['app_to_background', 'app_terminating', 'web_app_error'];

  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          paddingRight: '10px',
          height: '100px',
        }}
      >
        <Box
          height="100%"
          flex={0.15}
          display="flex"
          alignItems="center"
          justifyContent="space-around"
        >
          <Box
            height={10}
            width={10}
            borderRadius={20}
            bgcolor={
              offlineDeployments.includes(alert.lastReqType)
                ? config.colors.orange
                : config.colors.blue
            }
          />
          <TabletAndroidIcon fontSize="large" />
        </Box>
        <Box
          paddingLeft="20px"
          flex={0.65}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="space-around"
        >
          <Typography variant="subtitle1">{formatEventType(alert.lastReqType)}</Typography>
          <Typography
            fontWeight={500}
            variant="h6"
            color={
              offlineDeployments.includes(alert.lastReqType)
                ? config.colors.orange
                : config.colors.blue
            }
          >
            {device?.name || '?'}
          </Typography>
          <Typography variant="caption">{timeAgo(alert.alertTime)}</Typography>
        </Box>
        <Box height="100%" flex={0.2} display="flex" alignItems="center" justifyContent="center">
          <IconButton onClick={() => device && navigate(`/device/${device?.device_id}`)}>
            <VisibilityIcon fontSize="large" />
          </IconButton>
        </Box>
      </Box>
      <Divider />
    </>
  );
};
