import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './dashboard.scss';

import { AuthProvider } from 'context';
import {
  Dashboard,
  PrivateRoute,
  Login,
  Login2FA,
  Locations,
  CreateLocation,
  ViewLocation,
  EditDevice,
} from 'routes';
import Profile from 'routes/Profile';
import { Users } from 'routes/Users';
import { CreateUser } from 'routes/CreateUser';
import { EditUser } from 'routes/EditUser';
import { Clients } from 'routes/Clients';
import { EditClient } from 'routes/EditClient';
import ForgotPassword from 'routes/ForgotPassword';
import { RecoverPasswordLink } from 'routes/RecoverPasswordLink';
import { UpdateEmailLink } from 'routes/UpdateEmailLink';
import { UpdateLocation } from 'routes/UpdateLocation';
import { NotFound } from 'routes/NotFound';

const App: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/login2FA" element={<Login2FA />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/locations"
            element={
              <PrivateRoute>
                <Locations />
              </PrivateRoute>
            }
          />
          <Route
            path="/clients"
            element={
              <PrivateRoute>
                <Clients />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-location"
            element={
              <PrivateRoute>
                <CreateLocation />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/create"
            element={
              <PrivateRoute>
                <CreateUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/location-devices"
            element={
              <PrivateRoute>
                <ViewLocation />
              </PrivateRoute>
            }
          />
          // Routes with dynamic parts
          <Route path="/recoverPassword/:token" element={<RecoverPasswordLink />} />
          <Route path="/updateEmail/:token" element={<UpdateEmailLink />} />
          <Route
            path="/clients/edit/:clientId"
            element={
              <PrivateRoute>
                <EditClient />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/edit/:userId"
            element={
              <PrivateRoute>
                <EditUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/location/:id"
            element={
              <PrivateRoute>
                <UpdateLocation />
              </PrivateRoute>
            }
          />
          <Route
            path="/device/:id"
            element={
              <PrivateRoute>
                <EditDevice />
              </PrivateRoute>
            }
          />
          // Wildcard route at the end
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
