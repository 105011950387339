import React, { useEffect, useState } from 'react';
import { Button, FormControl, MenuItem, Menu, Typography, Box } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import { Airport, City, Country } from 'types';
import { config } from 'config';

interface AirportDropdownProps {
  setSelectedCity: React.Dispatch<React.SetStateAction<City | null>>;
  selectedCountry: Country | null;
  selectedCity: City | null;
  selectedAirport: Airport | null;
  setSelectedAirport: React.Dispatch<React.SetStateAction<Airport | null>>;
  locations: Country[];
}

export const AirportDropdown: React.FC<AirportDropdownProps> = ({
  selectedAirport,
  selectedCity,
  selectedCountry,
  setSelectedAirport,
  locations,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [airportsForCountry, setAirportsForCountries] = useState<Airport[] | null>(null);

  useEffect(() => {
    if (selectedCity?.airports) {
      console.log('selectedCity:', selectedCity);

      setAirportsForCountries(selectedCity?.airports);
    }
  }, [selectedCountry, selectedCity, locations]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (airport: Airport | null) => {
    handleClose();
    setSelectedAirport(airport);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <Button
        onClick={handleClick}
        variant={selectedCity ? 'contained' : 'outlined'}
        disabled={!selectedCity}
        style={{
          padding: '5px',
          marginRight: '10px',
          width: '130px',
          backgroundColor: selectedCity ? config.colors.blue : 'inherit',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '12px',
          display: 'flex',
        }}
      >
        <Box flex={0.25} display="flex" alignItems="center" justifyContent="center">
          <PublicIcon style={{ flexShrink: 0 }} />
        </Box>
        <Box
          flex={0.75}
          component="span"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {selectedAirport ? selectedAirport.name : 'Airport'}
        </Box>
      </Button>

      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {airportsForCountry && airportsForCountry.length > 0 ? (
          <>
            {airportsForCountry.map((airport) => {
              return (
                <MenuItem key={airport.airport_id} onClick={() => handleMenuItemClick(airport)}>
                  {airport.name}
                </MenuItem>
              );
            })}
            {selectedAirport && (
              <MenuItem
                sx={{ color: 'red' }}
                key="null-option"
                onClick={() => handleMenuItemClick(null)}
              >
                Remove filter
              </MenuItem>
            )}
          </>
        ) : (
          <Typography>
            No airports found within {selectedCountry?.name} - {selectedCity?.name}
          </Typography>
        )}
      </Menu>
    </FormControl>
  );
};
