import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CircularProgress,
  Alert,
  Typography,
} from '@mui/material';
import { config } from 'config';
import { Loader, Navbar } from 'components';

export const Clients = (): JSX.Element => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [clientsData, setClientsData] = useState([]);

  useEffect(() => {
    const loadClientsList = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) throw new Error('No access token');
        const res = await fetch(`${config.endpointUrl}/clientsList`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({ accessToken }),
        });
        const data = await res.json();
        if (data.error) throw new Error(data.error);
        setClientsData(data.clients);
        setIsLoading(false);
      } catch (err: any) {
        console.log(err);
        setIsLoading(false);
        setError(true);
      }
    };

    loadClientsList();
  }, []);

  return (
    <Navbar>
      <div
        style={{
          padding: '20px',
          width: '100%',
          height: '100%',
          backgroundColor: config.colors.background,
        }}
      >
        {isLoading && <Loader />}

        <Box pt={5} display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="h5">Clients</Typography>
            <Typography>View and manage your clients</Typography>
          </Box>
          <Box>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => navigate('/settings/clients/create')}
            >
              Create Client
            </Button>
          </Box>
        </Box>

        <div className="content">
          <div className="content-main">
            {isLoading && (
              <Box className="loader">
                <CircularProgress />
              </Box>
            )}
            {error && <Alert severity="error">Clients List loding failure</Alert>}
            {clientsData?.length > 0 && (
              <>
                <TableContainer className="clients-table" component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Client Name</TableCell>
                        <TableCell>Logo</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clientsData.map((row: any) => (
                        <TableRow key={row.clientId}>
                          <TableCell>
                            <div className="client-name">{row.clientName}</div>
                          </TableCell>
                          <TableCell>
                            {row.imageUrl && (
                              <img
                                className="client-logo"
                                src={row.imageUrl}
                                alt={row.clientName}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => navigate(`/clients/edit/${row.clientId}`)}
                            >
                              Edit
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </div>
        </div>
      </div>
    </Navbar>
  );
};
