import { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { config } from 'config';

type IChangePasswordModal = {
  openChangePassword: boolean;
  setOpenChangePassword: (openChangePassword: boolean) => void;
  setUpdatePasswordSuccess: (updatePasswordSuccess: boolean) => void;
};

export const ChangePasswordModal = (props: IChangePasswordModal): JSX.Element => {
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');

  const [error, setError] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [currentPasswordErrorMessage, setCurrentPasswordErrorMessage] = useState<string>('');
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState<string>('');
  useEffect(() => {
    props.setUpdatePasswordSuccess(false);
  }, []);

  const onCloseDialog = (_: unknown, reason: string) => {
    if (reason === 'backdropClick') return;
    handleClose();
  };

  const handleClose = () => {
    props.setOpenChangePassword(false);
  };

  const handleChangePassword = async () => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      if (!accessToken) throw new Error('No access token');
      setIsDisabled(true);
      const res = await fetch(`${config.endpointUrl}/changePassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({ accessToken, currentPassword, newPassword, confirmNewPassword }),
      });
      const data = await res.json();
      console.info(data);
      if (data.error) throw data;
      props.setUpdatePasswordSuccess(true);
      handleClose();
    } catch (err: any) {
      console.log(err);
      setError(true);
      if (err.isNewPassword === true) {
        setNewPasswordErrorMessage(err.error);
        setCurrentPasswordErrorMessage('');
      } else if (err.isNewPassword === false) {
        setNewPasswordErrorMessage('');
        setCurrentPasswordErrorMessage(err.error);
      }
      setIsDisabled(false);
    }
  };

  return (
    <div>
      <Dialog
        open={props.openChangePassword}
        onClose={onCloseDialog}
        className="change-password-dialog-wrapper"
      >
        <DialogTitle className="alert-dialog-title">Change Password</DialogTitle>
        <DialogContent>
          <DialogContentText className="alert-dialog-description">
            <p>
              Please enter your current password, and your new password twice to change your
              password.
            </p>
          </DialogContentText>
          <TextField
            className="current-password-input input"
            type="password"
            label="Current Password"
            variant="filled"
            value={currentPassword}
            onInput={(e: any) => setCurrentPassword(e.target.value)}
            error={error}
            helperText={currentPasswordErrorMessage}
          />
          <TextField
            className="new-password-input input"
            type="password"
            label="New Password"
            variant="filled"
            value={newPassword}
            onInput={(e: any) => setNewPassword(e.target.value)}
            error={error}
            helperText={newPasswordErrorMessage}
          />
          <TextField
            className="confirm-new-password-input input"
            type="password"
            label="Confirm New Password"
            variant="filled"
            value={confirmNewPassword}
            onInput={(e: any) => setConfirmNewPassword(e.target.value)}
            error={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button
            onClick={handleChangePassword}
            color="error"
            variant="contained"
            disabled={isDisabled}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
