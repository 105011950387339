import { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Breadcrumbs,
} from '@mui/material';

import { DeleteConfirm, Loader, Navbar } from 'components';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'context';
import { config } from 'config';

export const EditUser = (): JSX.Element => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingFailed, setLoadingFailed] = useState<boolean>(false);

  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');

  const [accessRights, setAccessRights] = useState<string>('');
  const [clientId, setClientId] = useState<string>('');
  const [clientsData, setClientsData] = useState([]);

  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) throw new Error('No access token');
        const res = await fetch(`${config.endpointUrl}/getUserConfig`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({ userId: params.userId, accessToken }),
        });
        const data = await res.json();
        if (data.error) throw new Error(data.error);
        const { email, firstName, surname, accessRights, clientId } = data;
        if (email) {
          setEmail(email);
        }
        if (firstName) {
          setFirstName(firstName);
        }
        if (surname) {
          setSurname(surname);
        }
        if (accessRights?.management) {
          setAccessRights(accessRights?.management);
        }
        if (clientId) {
          setClientId(clientId);
        }
        setIsLoading(false);
      } catch (err: any) {
        console.log(err);
        setLoadingFailed(true);
        setIsLoading(false);
      }
    };

    const loadClientsList = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) throw new Error('No access token');
        const res = await fetch(`${config.endpointUrl}/clientsList`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({ accessToken }),
        });
        const data = await res.json();
        setClientsData(data.clients);
        if (data.error) throw new Error(data.error);
      } catch (err: any) {
        console.log(err);
        setError(true);
      }
    };

    const loadData = async () => {
      loadUser();
      loadClientsList();
    };
    loadData();
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) return;
      setIsDisabled(true);
      const res = await fetch(`${config.endpointUrl}/updateUserConfig`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          userId: params.userId,
          email,
          firstName,
          surname,
          accessRights,
          clientId,
          accessToken,
        }),
      });
      const data = await res.json();
      if (data.error) throw new Error(data.error);
      setError(false);
      setIsDisabled(false);
      navigate('/users');
    } catch (err: any) {
      setError(true);
      console.log(err);
    }
  };

  return (
    <Navbar>
      <div
        style={{
          padding: '20px',
          width: '100%',
          height: '100%',
          backgroundColor: config.colors.background,
        }}
      >
        {isLoading && <Loader />}
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Button variant="text" color="inherit" onClick={() => navigate(-1)}>
            {'<-'} Back
          </Button>
        </Breadcrumbs>

        <Box pt={5} display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="h5">Edit User</Typography>
            <Typography>Change the details of a user</Typography>
          </Box>
          <Box>
            <Button color="error" variant="contained" onClick={() => setOpenDelete(true)}>
              Delete User
            </Button>
          </Box>
        </Box>
        <div className="content">
          <div className="content-main">
            {loadingFailed && !isLoading && <Alert severity="error">User not found</Alert>}
            {!loadingFailed && !isLoading && (
              <form className="data-form" onSubmit={onSubmit}>
                <TextField
                  className="email-input input"
                  label="Email"
                  variant="filled"
                  value={email}
                  error={error}
                  // helperText={errorMessage}
                  onInput={(e: any) => setEmail(e.target.value)}
                  disabled={isDisabled}
                />
                <TextField
                  className="email-input input"
                  label="First Name"
                  variant="filled"
                  value={firstName}
                  error={error}
                  // helperText={errorMessage}
                  onInput={(e: any) => setFirstName(e.target.value)}
                  disabled={isDisabled}
                />
                <TextField
                  className="surname-input input"
                  label="Surname"
                  variant="filled"
                  value={surname}
                  error={error}
                  // helperText={errorMessage}
                  onInput={(e: any) => setSurname(e.target.value)}
                  disabled={isDisabled}
                />
                <FormLabel>Permissions:</FormLabel>
                <RadioGroup row>
                  {user?.accessRights?.management === '111' && (
                    <FormControlLabel
                      value="111"
                      control={
                        <Radio
                          checked={accessRights === '111'}
                          onChange={(e: any) => setAccessRights(e.target.value)}
                        />
                      }
                      label="Super User"
                    />
                  )}
                  {(user?.accessRights?.management === '111' ||
                    user?.accessRights?.management === '11') && (
                    <FormControlLabel
                      value="11"
                      control={
                        <Radio
                          checked={accessRights === '11'}
                          onChange={(e: any) => setAccessRights(e.target.value)}
                        />
                      }
                      label="Admin"
                    />
                  )}
                  <FormControlLabel
                    value="0"
                    control={
                      <Radio
                        checked={accessRights === '0'}
                        onChange={(e: any) => setAccessRights(e.target.value)}
                      />
                    }
                    label="User"
                  />
                </RadioGroup>
                <FormControl
                  variant="filled"
                  fullWidth
                  className="input"
                  disabled={isDisabled || accessRights === '111'}
                  error={error}
                >
                  <InputLabel>Client</InputLabel>
                  <Select
                    value={clientId}
                    label="Select Client"
                    onChange={(e) => setClientId(e.target.value as string)}
                  >
                    {clientsData.map((client: any) => (
                      <MenuItem value={client.clientId}>{client.clientName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="button-wrapper">
                  <Button variant="contained" type="submit" disabled={isDisabled}>
                    Update User Details
                  </Button>
                </div>
              </form>
            )}
            {openDelete && (
              <DeleteConfirm
                endpoint="/deleteUser"
                idName="userId"
                currentId={params.userId}
                openDelete={openDelete}
                setOpenDelete={setOpenDelete}
                redirectUrl="/users"
                dialogTitle="Confirm to delete user"
                diglogDescription="Are you sure you want to delete this user?"
              />
            )}
          </div>
        </div>
      </div>
    </Navbar>
  );
};
