import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Loader } from 'components/shared/Loader';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Device, WebApp } from 'types';
import { addDevice } from 'utils/apiRequests';

interface EditDeviceFormProps {
  webApps: WebApp[] | null;
  device: Device;
}

export const EditDeviceForm: React.FC<EditDeviceFormProps> = ({ device, webApps }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deviceName, setDeviceName] = useState<string>(device.name);
  const [webAppId, setWebAppId] = useState<number>(device.web_app_id);

  const navigate = useNavigate();

  useEffect(() => {
    setDeviceName(device.name);
    setWebAppId(device.web_app_id);
  }, [device]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const updatedDevice = { ...device };
    updatedDevice.name = deviceName;
    updatedDevice.web_app_id = webAppId;
    const resp = await addDevice(updatedDevice);
    if (resp) {
      alert('Successfully updated device!');
      navigate('/locations');
    } else {
      alert('Something went wrong when updating device');
    }
    setIsLoading(false);
  };

  const isFormValid = deviceName.length !== 0;

  return (
    <form onSubmit={handleSubmit}>
      {isLoading && <Loader />}

      <Typography variant="h6">Edit device:</Typography>

      <FormControl fullWidth margin="normal">
        <TextField
          required
          label="Country"
          value={deviceName}
          onChange={(e) => setDeviceName(e.target.value)}
          variant="outlined"
        />
      </FormControl>

      <FormControl sx={{ my: '20px' }} fullWidth>
        <InputLabel required>Web App</InputLabel>
        <Select
          value={webAppId}
          label="Web App &nbsp;"
          onChange={(e) => setWebAppId(Number(e.target.value))}
        >
          {webApps?.map((webA) => {
            return <MenuItem value={webA.app_id}>{webA.name}</MenuItem>;
          })}
        </Select>
      </FormControl>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isFormValid || isLoading}
      >
        Save
      </Button>
    </form>
  );
};
