import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  TextField,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Breadcrumbs,
  Box,
  Typography,
  Divider,
} from '@mui/material';
import { config } from 'config';
import { Loader, Navbar } from 'components';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export const CreateUser = (): JSX.Element => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [accessRights, setAccessRights] = useState<string>('111');
  const [clientId, setClientId] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [clientsData, setClientsData] = useState([]);

  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const loadClientsList = async () => {
      try {
        setIsLoading(true);
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) throw new Error('No access token');
        const res = await fetch(`${config.endpointUrl}/clientsList`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({ accessToken }),
        });
        const data = await res.json();
        if (data.error) throw new Error(data.error);
        setClientsData(data.clients);
        setIsLoading(false);
      } catch (err: any) {
        console.log(err);
        setIsLoading(false);
      }
    };

    loadClientsList();
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) throw new Error('No user token');
      setIsLoading(true);
      const res = await fetch(`${config.endpointUrl}/createUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          accessToken,
          email,
          username,
          firstName,
          surname,
          password,
          confirmPassword,
          accessRights,
          clientId,
        }),
      });
      const data = await res.json();
      if (res.status !== 200) throw new Error(data.error);
      setError(true);
      navigate('/users');
    } catch (err: any) {
      console.log(err);
      setIsLoading(false);
      setError(true);
    }
  };

  return (
    <Navbar>
      <div
        style={{
          padding: '20px',
          width: '100%',
          height: '100%',
          backgroundColor: config.colors.background,
        }}
      >
        {isLoading && <Loader />}
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Button variant="text" color="inherit" onClick={() => navigate(-1)}>
            {'<-'} Back
          </Button>
        </Breadcrumbs>

        <Box pt={5} display="flex" flexDirection="column">
          <Typography variant="h5">Create User</Typography>
          <Typography>A new user will be created and sent an email</Typography>

          <Divider sx={{ mt: 2 }} />
        </Box>
        <div className="content">
          <div className="content-main">
            <form className="data-form" onSubmit={onSubmit}>
              <TextField
                className="email-input input"
                label="Email"
                variant="filled"
                value={email}
                error={error}
                // helperText={errorMessage}
                onInput={(e: any) => setEmail(e.target.value)}
                disabled={isLoading}
              />
              <TextField
                className="username-input input"
                label="Username"
                variant="filled"
                value={username}
                error={error}
                // helperText={errorMessage}
                onInput={(e: any) => setUsername(e.target.value)}
                disabled={isLoading}
              />
              <TextField
                className="first-name-input input"
                label="First Name"
                variant="filled"
                value={firstName}
                error={error}
                // helperText={errorMessage}
                onInput={(e: any) => setFirstName(e.target.value)}
                disabled={isLoading}
              />
              <TextField
                className="surname-input input"
                label="Surname"
                variant="filled"
                value={surname}
                error={error}
                // helperText={errorMessage}
                onInput={(e: any) => setSurname(e.target.value)}
                disabled={isLoading}
              />
              <TextField
                className="password-input input"
                type="password"
                label="Password"
                variant="filled"
                value={password}
                error={error}
                // helperText={errorMessage}
                onInput={(e: any) => setPassword(e.target.value)}
                disabled={isLoading}
              />
              <TextField
                className="confirm-password-input input"
                type="password"
                label="Confirm Password"
                variant="filled"
                value={confirmPassword}
                error={error}
                // helperText={errorMessage}
                onInput={(e: any) => setConfirmPassword(e.target.value)}
                disabled={isLoading}
              />
              <FormLabel>Permissions:</FormLabel>
              <RadioGroup row>
                <FormControlLabel
                  value="111"
                  control={
                    <Radio
                      checked={accessRights === '111'}
                      onChange={(e: any) => setAccessRights(e.target.value)}
                    />
                  }
                  label="Super User"
                />

                <FormControlLabel
                  value="11"
                  control={
                    <Radio
                      checked={accessRights === '11'}
                      onChange={(e: any) => setAccessRights(e.target.value)}
                    />
                  }
                  label="Admin"
                />

                <FormControlLabel
                  value="0"
                  control={
                    <Radio
                      checked={accessRights === '0'}
                      onChange={(e: any) => setAccessRights(e.target.value)}
                    />
                  }
                  label="User"
                />
              </RadioGroup>
              <FormControl
                variant="filled"
                fullWidth
                className="input"
                disabled={isLoading || accessRights === '111'}
                error={error}
              >
                <InputLabel>Client</InputLabel>
                <Select
                  value={clientId}
                  label="Select Client"
                  onChange={(e) => setClientId(e.target.value as string)}
                >
                  {clientsData.map((client: any) => (
                    <MenuItem value={client.clientId}>{client.clientName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="button-wrapper">
                <Button variant="contained" type="submit" disabled={isLoading}>
                  Send
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Navbar>
  );
};
