import { Loader, Navbar } from 'components';
import { config } from 'config';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import { Country, Device, DeviceStatus, WebApp } from 'types';
import { getDevices, getLocations } from 'utils/apiRequests/locations';
import { Box, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { deleteDevice, getDeviceStatus, getWebApps } from 'utils/apiRequests';
import { timeAgo } from 'utils/utils';
import { useAuth } from 'context';

export const ViewLocation = () => {
  const [devices, setDevices] = useState<Device[] | null>(null);
  const [deviceStatus, setDeviceStatus] = useState<DeviceStatus[] | null>(null);
  const [countries, setCountries] = useState<Country[] | null>(null);
  const [webApps, setWebApps] = useState<WebApp[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const deviceId = searchParams.get('deviceId');

  const countryId = id?.split('-')[0];
  const cityId = id?.split('-')[1];
  const airportId = id?.split('-')[2];
  const terminalId = id?.split('-')[3];

  console.log('countryId:', countryId);
  console.log('cityId:', cityId);

  const { user } = useAuth();

  const navigate = useNavigate();

  const getDevicesHelper = async () => {
    setIsLoading(true);

    let devicesResp;

    if (!id) return;
    if (id === 'active' || id === 'offline') {
      devicesResp = await getDevices();
    } else {
      devicesResp = await getDevices(countryId);
    }

    const countriesResp = await getLocations();
    const deviceStatusResp = await getDeviceStatus();
    const webAppsResp = await getWebApps();
    if (devicesResp) {
      if (!user?.isDeveloper) {
        setDevices(devicesResp.filter((dvc) => !dvc.test));
      } else {
        setDevices(devicesResp);
      }
    }

    if (countriesResp) {
      setCountries(countriesResp);
    }
    if (deviceStatusResp) {
      setDeviceStatus(deviceStatusResp);
    }
    if (webAppsResp) {
      setWebApps(webAppsResp);
    }

    setIsLoading(false);
  };

  const deleteDeviceConfirmation = async (deviceId: string) => {
    if (
      window.confirm(
        'Are you sure you want to delete this device. All data will be permanently lost.',
      )
    ) {
      setIsLoading(true);
      await deleteDevice(deviceId);
      getDevicesHelper();

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDevicesHelper();
  }, [id]);

  return (
    <Navbar>
      <div
        style={{
          padding: '20px',
          width: '100%',
          height: '100%',
          backgroundColor: config.colors.background,
        }}
      >
        {isLoading && <Loader />}
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" component={RouterLink} to="/locations">
            Locations
          </Link>
          <Typography color="textPrimary"> {id}</Typography>
        </Breadcrumbs>

        <Box marginTop="20px">
          {devices && devices.length > 0 ? (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {/* Add your column headers here */}
                    <TableCell>Name</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>Airport</TableCell>
                    <TableCell>Terminal</TableCell>
                    <TableCell>Experience</TableCell>
                    <TableCell>Last Connected</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Battery Health</TableCell>
                    <TableCell>Actions</TableCell> {/* New header for actions */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {devices.map((device, index) => {
                    if (deviceId && device.device_id !== deviceId) return null;
                    const matchingCountry = countries?.find(
                      (ctr) => ctr.country_id === device.country_id,
                    );
                    const matchingCity = matchingCountry?.cities.find(
                      (cty) => cty.city_id === device.city_id,
                    );
                    const matchingAirport = matchingCity?.airports.find(
                      (air) => air.airport_id === device.airport_id,
                    );
                    const matchingTerminal = matchingAirport?.terminals.find(
                      (trm) => trm.terminal_id === device.terminal_id,
                    );

                    const matchingDeviceStatus = deviceStatus?.find(
                      (dvs) => dvs.deviceId === device.device_id,
                    );

                    //here we filter active and inactive devices
                    if (id === 'active' && !matchingDeviceStatus?.active) return null;
                    if (id === 'offline' && matchingDeviceStatus?.active) return null;

                    // here we filter devices based on their location
                    if (cityId && matchingCity && matchingCity.city_id !== +cityId) return null;
                    if (airportId && matchingAirport && matchingAirport.airport_id !== +airportId)
                      return null;
                    if (
                      terminalId &&
                      matchingTerminal &&
                      matchingTerminal.terminal_id !== +terminalId
                    )
                      return null;

                    const matchingWebApp = webApps?.find((wap) => wap.app_id === device.web_app_id);

                    return (
                      <TableRow key={index}>
                        {/* Add your table cells here corresponding to the data */}
                        <TableCell>{device?.name || '-'}</TableCell>
                        <TableCell>{matchingCity?.name || '-'}</TableCell>
                        <TableCell>{matchingAirport?.name || '-'}</TableCell>
                        <TableCell>{matchingTerminal?.name || '-'}</TableCell>
                        <TableCell>{matchingWebApp?.name}</TableCell>
                        <TableCell>
                          {matchingDeviceStatus?.last_online
                            ? timeAgo(matchingDeviceStatus?.last_online)
                            : 'Unknown'}
                        </TableCell>
                        <TableCell align="left">
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-around',
                            }}
                          >
                            {matchingDeviceStatus ? (
                              <>
                                {matchingDeviceStatus?.active ? (
                                  <>
                                    <CheckCircleIcon sx={{ color: 'green' }} />
                                    <Typography>Active</Typography>
                                  </>
                                ) : (
                                  <>
                                    <WarningIcon sx={{ color: 'orange' }} />
                                    <Typography>Offline</Typography>
                                  </>
                                )}
                              </>
                            ) : (
                              <Typography>Unknown</Typography>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {matchingDeviceStatus?.bat_level
                            ? `${matchingDeviceStatus?.bat_level}%`
                            : '?'}
                        </TableCell>
                        <TableCell>
                          <IconButton>
                            <EditIcon
                              color="action"
                              onClick={() => navigate(`/device/${device.device_id}`)}
                            />
                          </IconButton>
                          <IconButton onClick={() => deleteDeviceConfirmation(device.device_id)}>
                            <DeleteIcon color="action" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No devices in this location</Typography>
          )}
        </Box>
      </div>
    </Navbar>
  );
};
