import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AppsIcon from '@mui/icons-material/Apps';
import GroupIcon from '@mui/icons-material/Group';
import { CotyLogo } from 'utils/resources';
import { Avatar, Typography } from '@mui/material';
import { useAuth } from 'context';
import { generateAvatar } from 'utils/utils';
import { config } from 'config';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';

interface NavbarProps {
  children: React.ReactNode | React.ReactNode[];
}

export const Navbar: React.FC<NavbarProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { user, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }} height="100vh">
      <AppBar
        position="static"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          width: '100%',
          paddingRight: '40px',
          bgcolor: config.colors.primaryColor,
        }}
      >
        <Toolbar>
          <IconButton
            onClick={() => setIsOpen(!isOpen)}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <CotyLogo />
        </Toolbar>

        <Box display="flex" flexDirection="row" alignItems="center">
          <IconButton
            onClick={() => navigate('/dashboard')}
            sx={{ marginRight: '20px', display: 'flex', flexDirection: 'column' }}
          >
            <NotificationsIcon sx={{ color: 'white' }} fontSize="large" />
            <Typography fontSize={13} color={config.colors.lightGray}>
              Notifications
            </Typography>
          </IconButton>
          <IconButton
            sx={{ marginRight: '40px', display: 'flex', flexDirection: 'column' }}
            onClick={logout}
          >
            <LogoutIcon sx={{ color: 'white' }} fontSize="large" />
            <Typography fontSize={13} color={config.colors.lightGray}>
              Logout
            </Typography>
          </IconButton>

          <Avatar sx={{ width: 46, height: 46 }}>
            {generateAvatar(user?.firstName, user?.surname)}
          </Avatar>
        </Box>
      </AppBar>
      <Box display="flex" width="100%" height="calc(100vh - 105px)">
        {isOpen && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            flexDirection="column"
            bgcolor="white"
            width="100px"
            paddingTop="20px"
          >
            <IconButton
              sx={{ display: 'flex', flexDirection: 'column' }}
              onClick={() => navigate('/dashboard')}
            >
              <HomeIcon
                fontSize="large"
                sx={{
                  color:
                    location.pathname === '/dashboard'
                      ? config.colors.primaryColor
                      : config.colors.darkGray,
                }}
              />
              <Typography fontSize={13}>Dashboard</Typography>
            </IconButton>
            <IconButton
              sx={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}
              onClick={() => navigate('/locations')}
            >
              <LocationOnIcon
                fontSize="large"
                sx={{
                  color:
                    location.pathname === '/locations'
                      ? config.colors.primaryColor
                      : config.colors.darkGray,
                }}
              />
              <Typography fontSize={13}>Locations</Typography>
            </IconButton>
            <IconButton
              sx={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}
              onClick={() => navigate('/users')}
            >
              <GroupIcon
                fontSize="large"
                sx={{
                  color:
                    location.pathname === '/users'
                      ? config.colors.primaryColor
                      : config.colors.darkGray,
                }}
              />
              <Typography fontSize={13}>Users</Typography>
            </IconButton>

            <IconButton
              sx={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}
              onClick={() => navigate('/clients')}
            >
              <AppsIcon
                fontSize="large"
                sx={{
                  color:
                    location.pathname === '/clients'
                      ? config.colors.primaryColor
                      : config.colors.darkGray,
                }}
              />
              <Typography fontSize={13}>Clients</Typography>
            </IconButton>
            <IconButton
              sx={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}
              onClick={() => navigate('/profile')}
            >
              <AccountBoxIcon
                fontSize="large"
                sx={{
                  color:
                    location.pathname === '/profile'
                      ? config.colors.primaryColor
                      : config.colors.darkGray,
                }}
              />
              <Typography fontSize={13}>Profile</Typography>
            </IconButton>
          </Box>
        )}
        {children}
      </Box>
    </Box>
  );
};
