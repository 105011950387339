// This component referenced these two pages below
// https://stackblitz.com/edit/react-drag-and-drop-file-upload
// https://refine.dev/blog/how-to-base64-upload/#what-is-base64-encoding

import { useState, useRef, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import Cancel from '@mui/icons-material/Cancel';

type IFileUpload = {
  disabled?: boolean;
  onChange: (base64: string) => void;
  imageUrl?: string;
  setImageUrl?: (imageUrl: string) => void;
  setNoImage: (noImage: boolean) => void;
  description: string;
};

export const FileUpload = (props: IFileUpload): JSX.Element => {
  const imgRef = useRef<HTMLImageElement>(null);

  const [selectedFile, setSelectedFile] = useState<File>(null!);

  useEffect(() => {
    if (props.imageUrl) {
      setSelectedFile(null!);
      if (imgRef?.current) {
        imgRef.current.src = props.imageUrl;
      }
    }
  }, [props.imageUrl]);

  const convertBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onUpload = async (files: File[]) => {
    if (!files?.length || !files[0]) return;
    const file = files[0];
    if (!['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml'].includes(file.type)) return;
    setSelectedFile(file);
    const base64: string = await convertBase64(file);
    if (imgRef?.current) {
      imgRef.current.src = base64;
      props.onChange(base64);
      props.setNoImage(false);
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files) {
      await onUpload(e.target.files as any);
    }
  };

  const removeImage = (e: any) => {
    e.stopPropagation();
    setSelectedFile(null!);
    if (props.setImageUrl) props.setImageUrl('');
    if (imgRef?.current) {
      imgRef.current.src = '';
      props.onChange('');
      props.setNoImage(true);
    }
  };

  return (
    <div>
      <Dropzone onDrop={onUpload} multiple={false} disabled={props.disabled}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} onChange={handleFileChange} />
              {(selectedFile?.name || props.imageUrl) && (
                <div className="preview-wrapper">
                  {!props.disabled && <Cancel className="cancel-button" onClick={removeImage} />}
                  <img className="preview-image" ref={imgRef} />
                </div>
              )}
              <p>{props.description}</p>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
};
