import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Container,
  CssBaseline,
  Box,
  Card,
  CircularProgress,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { config } from 'config';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context';

export const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const { setUser } = useAuth();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    // Handle the login logic here
    console.log('Username:', username, 'Password:', password);

    try {
      setIsLoading(true);
      const resp = await axios.post(`${config.endpointUrl}/loginUser`, { username, password });

      const data = resp.data;

      if (!data.accessToken || !data.userId) {
        throw new Error('No access token or user ID in response.');
      }

      setUser({ ...data });

      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('userId', data.userId);

      console.info(data);
      setIsLoading(false);

      navigate('/login2FA');
    } catch (err: any) {
      console.log(err);
      setError(true);
      setIsLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          height: '100vh',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Card sx={{ padding: '40px' }}>
          <Typography component="h1" variant="h5">
            Login User
          </Typography>
          <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
            Login with your Play Monitoring Account
          </Typography>

          {error && (
            <Alert onClose={() => setError(false)} severity="error">
              Incorrect Username or Password
            </Alert>
          )}
          <Box component="form" sx={{ width: '100%', mt: 3 }} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              {isLoading ? <CircularProgress size={25} color="secondary" /> : 'LOGIN'}
            </Button>
          </Box>
          <Button
            disabled={isLoading}
            color="secondary"
            onClick={() => {
              navigate('/forgot-password');
            }}
          >
            FORGOT PASSWORD
          </Button>
        </Card>
      </Box>
    </Container>
  );
};
