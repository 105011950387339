import React, { useEffect, useState } from 'react';
import { Button, FormControl, MenuItem, Menu, Typography, Box } from '@mui/material';
import { config } from 'config';

import PublicIcon from '@mui/icons-material/Public';
import { Airport, City, Country } from 'types';
import { getCitiesByCountryId } from 'utils/utils';

interface CityDropdownProps {
  setSelectedCity: React.Dispatch<React.SetStateAction<City | null>>;
  setSelectedAirport: React.Dispatch<React.SetStateAction<Airport | null>>;
  selectedCountry: Country | null;
  selectedCity: City | null;
  locations: Country[];
}

export const CityDropdown: React.FC<CityDropdownProps> = ({
  setSelectedCity,
  setSelectedAirport,
  selectedCountry,
  selectedCity,
  locations,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [citiesForCountry, setCitiesForCountries] = useState<City[] | null>(null);

  useEffect(() => {
    if (selectedCountry?.country_id || selectedCountry?.country_id === 0) {
      const citiesResp = getCitiesByCountryId(locations, selectedCountry.country_id);
      setCitiesForCountries(citiesResp);
    }
  }, [selectedCountry, locations]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (city: City | null) => {
    handleClose();
    setSelectedCity(city);
    setSelectedAirport(null);
  };

  console.log('citiesForCountry:', citiesForCountry);

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <Button
        onClick={handleClick}
        variant={selectedCountry ? 'contained' : 'outlined'}
        disabled={!selectedCountry}
        style={{
          padding: '5px',
          marginRight: '10px',
          width: '130px',
          backgroundColor: selectedCountry ? config.colors.blue : 'inherit',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '12px',
          display: 'flex',
        }}
      >
        <Box flex={0.25} display="flex" alignItems="center" justifyContent="center">
          <PublicIcon style={{ flexShrink: 0 }} />
        </Box>
        <Box
          flex={0.75}
          component="span"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {selectedCity ? selectedCity.name : 'City'}
        </Box>
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {citiesForCountry && citiesForCountry.length > 0 ? (
          <>
            {citiesForCountry.map((city) => {
              return (
                <MenuItem key={city.city_id} onClick={() => handleMenuItemClick(city)}>
                  {city.name}
                </MenuItem>
              );
            })}
            {selectedCity && (
              <MenuItem
                sx={{ color: 'red' }}
                key="null-option"
                onClick={() => handleMenuItemClick(null)}
              >
                Remove filter
              </MenuItem>
            )}
          </>
        ) : (
          <Typography>No cities found within {selectedCountry?.name}</Typography>
        )}
      </Menu>
    </FormControl>
  );
};
