import { useEffect, useState, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material/';
import { config } from 'config';

export const UpdateEmailLink = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams();
  const token = useMemo(() => params.token, [params]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    const checkUpdateEmailToken = async () => {
      try {
        const res = await fetch(`${config.endpointUrl}/checkUpdateEmailToken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({ token }),
        });
        const data = await res.json();
        if (data.error) throw new Error(data.error);
        setIsLoading(false);
        setTimeout(() => navigate('/login'), 5000);
      } catch (err: any) {
        console.log(err);
        navigate('/404');
      }
    };
    checkUpdateEmailToken();
  }, []);
  return (
    <div className="App">
      <div className="form-wrapper">
        <h1 className="form-title">Update Email</h1>
        {isLoading ? (
          <Box className="loader">
            <CircularProgress />
          </Box>
        ) : (
          <p className="form-description">
            Your email has been updated successfully. This page will redirect to the login page in 5
            seconds...
          </p>
        )}
      </div>
    </div>
  );
};
