import { useEffect, useState, useMemo } from 'react';
import { Box, Breadcrumbs, Button, Typography } from '@mui/material';
import { DeleteConfirm, ClientsForm, Navbar, Loader } from 'components';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate, useParams } from 'react-router-dom';
import { config } from 'config';

export const EditClient = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams();
  const clientId = useMemo(() => params.clientId, [params]);

  const [clientName, setClientName] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string>('');
  const [uploadedImage, setUploadedImage] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noImage, setNoImage] = useState<boolean>(false);

  const [openDelete, setOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    const loadClientData = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) throw new Error('No access token');
        const res = await fetch(`${config.endpointUrl}/getClientData`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({ accessToken, clientId }),
        });

        const data = await res.json();
        if (data.error) throw new Error(data.error);
        const { clientData } = data;
        if (!clientData) throw new Error('No client data');

        const { clientName, imageUrl } = clientData;

        if (clientName) {
          setClientName(clientName);
        }
        if (imageUrl) {
          setImageUrl(imageUrl);
        } else {
          setNoImage(true);
        }
      } catch (err: any) {
        console.log(err);
        navigate('/404');
      }
    };

    loadClientData();
  }, [clientId]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) throw new Error('No access token');
      const res = await fetch(`${config.endpointUrl}/updateClient`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({ accessToken, clientId, clientName, uploadedImage, noImage }),
      });
      const data = await res.json();
      if (data.error) throw data;
      setIsLoading(false);
      navigate('/settings/clients');
    } catch (err: any) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <Navbar>
      <div
        style={{
          padding: '20px',
          width: '100%',
          height: '100%',
          backgroundColor: config.colors.background,
        }}
      >
        {isLoading && <Loader />}
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Button variant="text" color="inherit" onClick={() => navigate(-1)}>
            {'<-'} Back
          </Button>
        </Breadcrumbs>

        <Box pt={5} display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="h5">Edit Client</Typography>
            <Typography>Edit Play Retail clients in our system</Typography>
          </Box>
          <Box>
            <Button color="error" variant="contained" onClick={() => setOpenDelete(true)}>
              Delete Client
            </Button>
          </Box>
        </Box>
        <div className="content">
          <div className="content-main">
            <ClientsForm
              onSubmit={onSubmit}
              clientName={clientName}
              setClientName={setClientName}
              uploadedImage={uploadedImage}
              setUploadedImage={setUploadedImage}
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
              isDisabled={isLoading}
              setNoImage={setNoImage}
              submitButtonText="Update"
            />
            {openDelete && (
              <DeleteConfirm
                endpoint="/deleteClient"
                idName="clientId"
                currentId={params.clientId}
                openDelete={openDelete}
                setOpenDelete={setOpenDelete}
                redirectUrl="/settings/clients"
                dialogTitle="Confirm to delete client"
                diglogDescription="Are you sure you want to delete this client?"
              />
            )}
          </div>
        </div>
      </div>
    </Navbar>
  );
};
