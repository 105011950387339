import { Chip, Stack } from '@mui/material';

type IUsersSettings = {
  management: string;
};

export const PermissionsTags = (props: IUsersSettings): JSX.Element => {
  return (
    <Stack direction="row" spacing={1}>
      {props.management === '111' && (
        <Chip label="Super User" className="super-user-tag permissions-tags" />
      )}
      {props.management === '11' && (
        <Chip label="Admin" className="permissions-tags" color="primary" />
      )}
      {props.management === '0' && (
        <Chip label="User" className="permissions-tags" color="warning" />
      )}
    </Stack>
  );
};
