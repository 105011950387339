import { City, Country, EventType, Terminal } from 'types';

export const generateAvatar = (name1?: string, name2?: string) => {
  let avatar = '';

  if (name1 && name1.length > 0) {
    avatar += name1[0];
  }

  if (name2 && name2.length > 0) {
    avatar += name2[0];
  }

  return avatar;
};

export const getCitiesByCountryId = (locations: Country[], countryId: number): City[] => {
  const country = locations.find((country) => country.country_id === countryId);
  return country ? country.cities : [];
};

export const getTerminalsByCountryIdCityIdAndAirportId = (
  locations: Country[],
  countryId: number,
  cityId: number,
  airportId: number,
): Terminal[] => {
  const country = locations.find((country) => country.country_id === countryId);
  if (country) {
    const city = country.cities.find((city) => city.city_id === cityId);
    if (city) {
      const airport = city.airports.find((airport) => airport.airport_id === airportId);
      return airport ? airport.terminals : [];
    }
  }
  return [];
};

export const calcFontSize = (charLen: number) => {
  if (charLen > 0 && charLen <= 10) {
    return '12px';
  }
  if (charLen > 10 && charLen <= 15) {
    return '12px';
  }
  if (charLen > 15 && charLen < 20) {
    return '8.5px';
  }
  return '8px';
};

export const timeAgo = (timestamp: number) => {
  const milliseconds = new Date().getTime() - timestamp;

  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const years = Math.floor(days / 365);

  // Check the time difference and decide the appropriate label
  if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (minutes < 60) {
    return minutes === 1 ? `A minute ago` : `${minutes} minutes ago`;
  } else if (hours < 24) {
    return hours === 1 ? `An hour ago` : `${hours} hours ago`;
  } else if (days < 365) {
    return days === 1 ? `A day ago` : `${days} days ago`;
  } else {
    return years === 1 ? `A year ago` : `${years} years ago`;
  }
};

export const parseBatteryLevel = (input: string) => {
  const match = input.match(/Battery level: (\d+)%/);
  if (match && match[1]) {
    return parseInt(match[1], 10);
  }
  return null;
};

export const formatEventType = (event: EventType) => {
  switch (event) {
    case 'web_app_loaded':
      return 'Web App Loaded';
    case 'web_app_error':
      return 'Web App Error';
    case 'ping':
      return 'Ping';
    case 'activity':
      return 'Activity';
    case 'app_to_background':
      return 'App Moved to Background';
    case 'app_reopening':
      return 'App Reopened';
    case 'app_terminating':
      return 'App Terminating';
    default:
      return event; // or throw an error if unexpected input
  }
};
