import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from 'types';
import { apiTest } from 'utils/apiRequests';

interface AuthContextType {
  logout: () => void;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  user: User | null;
}

const initialState = {
  user: null,
  logout: () => {},
  setUser: () => {},
};

const AuthContext = createContext<AuthContextType>(initialState);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  const navigate = useNavigate();

  const logout = () => {
    setUser(null);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
  };

  const checkLogin = async () => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      if (!accessToken) throw new Error('No access token');

      const res = await apiTest(accessToken);
      if (res) {
        setUser({ ...user, ...res });
        navigate('/dashboard');
      }
    } catch (err: any) {
      logout();
      navigate('/login');
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  return <AuthContext.Provider value={{ logout, user, setUser }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
