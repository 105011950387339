import { useEffect, useState, useMemo } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Box,
  Alert,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Loader, Navbar, PermissionsTags } from 'components';

import { useAuth } from 'context';
import { timeAgo } from 'utils/utils';
import { config } from 'config';

export const Users = (): JSX.Element => {
  const { user } = useAuth();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [userData, setUserData] = useState([]);

  const currentUserId = useMemo(
    () => localStorage.getItem('userId'),
    [localStorage.getItem('userId')],
  );

  useEffect(() => {
    const loadUsersList = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) throw new Error('No access token');
        const res = await fetch(`${config.endpointUrl}/usersList`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({ accessToken }),
        });
        const data = await res.json();
        if (data.error) throw new Error(data.error);
        setUserData(data.users);
        setIsLoading(false);
      } catch (err: any) {
        console.log(err);
        setIsLoading(false);
        setError(true);
      }
    };

    loadUsersList();
  }, []);

  return (
    <Navbar>
      <div
        style={{
          padding: '20px',
          width: '100%',
          height: '100%',
          backgroundColor: config.colors.background,
        }}
      >
        {isLoading && <Loader />}

        <Box pt={5} display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="h5">Users Settings</Typography>
            <Typography>View and manage your users settings</Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              size="medium"
              color="secondary"
              onClick={() => navigate('/users/create')}
              disabled={isLoading || user?.accessRights?.management === '0'}
            >
              Create User
            </Button>
          </Box>
        </Box>
        <div className="content">
          <div className="content-main">
            {isLoading && (
              <Box className="loader">
                <CircularProgress />
              </Box>
            )}
            {error && <Alert severity="error">Users List loding failure</Alert>}

            {userData?.length > 0 && (
              <>
                <TableContainer className="user-table" component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Last Login</TableCell>
                        <TableCell>Permissions</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userData.map((row: any) => (
                        <TableRow key={row.userId}>
                          <TableCell>
                            <div className="name">{`${row.firstName} ${row.surname}`}</div>
                          </TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>
                            <div className="login-time">
                              {row.lastLogin ? timeAgo(row.lastLogin * 1000) : 'Never'}
                            </div>
                            <div>{row.lastLogin ? timeAgo(row.lastLogin * 1000) : ''}</div>
                          </TableCell>
                          <TableCell>
                            <div className="permissions">
                              <PermissionsTags management={row.accessRights?.management} />
                            </div>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => navigate(`/users/edit/${row.userId}`)}
                              disabled={row.userId === currentUserId}
                            >
                              Edit
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </div>
        </div>
      </div>
    </Navbar>
  );
};
