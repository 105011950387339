import 'leaflet/dist/leaflet.css';

import React, { useEffect } from 'react';
import { Paper } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { Country, DisplayLocation } from 'types';
import L from 'leaflet';

// delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

interface LocationsMapProps {
  selectedCountry: Country | null;
  locations: DisplayLocation;
}

export const LocationsMap: React.FC<LocationsMapProps> = ({ locations, selectedCountry }) => {
  return (
    <Paper sx={{ width: '100%', height: '100%', zIndex: 1, overflow: 'hidden' }}>
      <MapContainer
        center={[51, -1]}
        zoom={selectedCountry ? 13 : 5}
        scrollWheelZoom={false}
        style={{ width: '100%', height: 'calc(100vh - 18rem)' }}
      >
        <LocationsMapBody locations={locations} selectedCountry={selectedCountry} />
      </MapContainer>
    </Paper>
  );
};

interface LocationsMapBodyProps {
  selectedCountry: Country | null;
  locations: DisplayLocation;
}

const LocationsMapBody: React.FC<LocationsMapBodyProps> = ({ locations }) => {
  const map = useMap();

  console.log('locations:', locations);

  useEffect(() => {
    if (locations && locations.length > 0) {
      const bounds: L.LatLngBoundsExpression = locations.map((loc) => [loc.lat, loc.lng]);
      map.fitBounds(bounds, { padding: [20, 20], animate: true });
    }
  }, [locations, map]);

  return (
    <>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {locations &&
        locations.length > 0 &&
        locations.map((loc) => {
          return (
            <Marker position={[loc.lat, loc.lng]}>
              <Popup>{loc.name}</Popup>
            </Marker>
          );
        })}
    </>
  );
};
