import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Box, Avatar, IconButton } from '@mui/material';
import { Airport, City, Country, DeviceStatus, Terminal, Device } from 'types';
import { config } from 'config';
import { useNavigate } from 'react-router-dom';
import CreateIcon from '@mui/icons-material/Create';

// import { useNavigate } from 'react-router-dom';

interface LocationItemProps {
  location: Country | City | Airport | Terminal;
  selectedCountry: Country | null;
  deviceStatus: DeviceStatus[] | null;
  devices: Device[] | null;
  selectedCity: City | null;
  selectedAirport: Airport | null;
}

const circleStyles = {
  position: 'absolute',
  top: 10,
  height: 30,
  width: 30,
  borderRadius: 30,
  zIndex: 9,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const centreStyles = {
  display: 'flex',
  alignItems: 'center',
  judtifyContent: 'center',
};

export const LocationItem: React.FC<LocationItemProps> = ({
  location,
  selectedCountry,
  devices,
  deviceStatus,
}) => {
  const [devicesOnline, setDevicesOnline] = useState<number | null>(null);
  const [devicesOffline, setDevicesOffline] = useState<number | null>(null);
  const navigate = useNavigate();

  console.log('devices:', devices);

  const getDeviceStatuses = () => {
    const deviceIds: string[] = [];
    console.log('location:', location);

    if ('country_id' in location) {
      devices?.forEach((dv) => {
        if (dv.country_id === location.country_id) {
          deviceIds.push(dv.device_id);
        }
      });
    }

    if ('city_id' in location) {
      devices?.forEach((dv) => {
        if (dv.city_id === location.city_id) {
          deviceIds.push(dv.device_id);
        }
      });
    }
    if ('airport_id' in location) {
      devices?.forEach((dv) => {
        if (dv.airport_id === location.airport_id) {
          deviceIds.push(dv.device_id);
        }
      });
    }
    if ('terminal_id' in location) {
      devices?.forEach((dv) => {
        if (dv.terminal_id === location.terminal_id) {
          deviceIds.push(dv.device_id);
        }
      });
    }

    const onlineDevicesResp = deviceStatus?.filter(
      (dvc) => dvc.active && deviceIds.includes(dvc.deviceId),
    );
    setDevicesOnline(onlineDevicesResp?.length ?? null);
    const offlineDevicesResp = deviceStatus?.filter(
      (dvc) => !dvc.active && deviceIds.includes(dvc.deviceId),
    );
    setDevicesOffline(offlineDevicesResp?.length ?? null);
  };

  const navigateToDevices = () => {
    let countryId = selectedCountry?.country_id;
    if ('country_id' in location) {
      countryId = location.country_id;
    }
    const urlArr = [`/location-devices?id=${countryId}`];

    if ('city_id' in location) {
      urlArr.push(String(location.city_id));
    }
    if ('airport_id' in location) {
      urlArr.push(String(location.airport_id));
    }
    if ('terminal_id' in location) {
      urlArr.push(String(location.terminal_id));
    }
    const url = urlArr.join('-');
    navigate(url);
  };

  const navigateToEditLocation = () => {
    const urlArr = ['/location/', selectedCountry?.country_id];
    if ('country_id' in location) {
      urlArr[1] = location.country_id;
    }
    if ('city_id' in location) {
      urlArr.push(location.city_id);
    }
    if ('airport_id' in location) {
      urlArr.push(location.airport_id);
    }
    if ('terminal_id' in location) {
      urlArr.push(location.terminal_id);
    }
    console.log('urlArr:', urlArr);
    console.log('location:', location);
    const url = urlArr.join('-');
    navigate(url);
  };

  useEffect(() => {
    getDeviceStatuses();
  }, [location]);

  // const navigate = useNavigate();
  return (
    <Card style={{ maxWidth: 345 }} sx={{ position: 'relative' }}>
      <CardMedia
        onClick={navigateToDevices}
        component="img"
        alt="Placeholder Image"
        height="140"
        image={location.image}
        title="Placeholder Image"
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      />
      <CardContent sx={{ display: 'flex', height: '100%', paddingY: '20px' }}>
        <Box sx={circleStyles} right={10} bgcolor={config.colors.green}>
          <Typography color="white">{devicesOnline}</Typography>
        </Box>
        <Box sx={circleStyles} right={45} bgcolor={config.colors.orange}>
          <Typography color="white">{devicesOffline}</Typography>
        </Box>
        <Box flex={0.2} {...centreStyles}>
          <Avatar sx={{ width: 32, height: 32 }}>
            <span style={{ fontSize: 14 }}>UK</span>
          </Avatar>
        </Box>
        <Box
          flex={0.8}
          sx={{
            paddingLeft: '5%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography gutterBottom variant="h6" fontSize={15} color={config.colors.darkGray}>
            {location.name}
          </Typography>
          <IconButton onClick={navigateToEditLocation}>
            <CreateIcon />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
};
