import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Container,
  CssBaseline,
  Box,
  Card,
  CircularProgress,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { config } from 'config';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context';

export const Login2FA: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [token, setToken] = useState('');

  const navigate = useNavigate();

  const { setUser, user } = useAuth();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    // Handle the login logic here
    console.log('token:', token);

    const accessToken = localStorage.getItem('accessToken');

    try {
      setIsLoading(true);
      const resp = await axios.post(`${config.endpointUrl}/check2FA`, {
        accessToken,
        token2FA: token,
      });

      const data = resp.data;

      setUser({ ...user, ...data });

      console.info(data);
      setIsLoading(false);

      navigate('/dashboard');
    } catch (err: any) {
      console.log(err);
      setError(true);
      setIsLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          height: '100vh',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Card sx={{ padding: '40px' }}>
          <Typography component="h1" variant="h5">
            Login User
          </Typography>
          <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
            Login with your Play Monitoring Account
          </Typography>

          {error && (
            <Alert onClose={() => setError(false)} severity="error">
              Incorrect token
            </Alert>
          )}
          <Box component="form" sx={{ width: '100%', mt: 3 }} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="token"
              label="Token"
              name="token"
              autoFocus
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              {isLoading ? <CircularProgress size={25} color="secondary" /> : 'LOGIN'}
            </Button>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};
