import React from 'react';

export const CotyLogo = () => {
  return (
    <svg
      strokeWidth={0}
      height={20}
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3640.15 665.77"
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="white"
          className="cls-1"
          d="m985.65,332.89c-.7-35.07,4.77-68.65,13.73-101.96,9.64-35.86,26.03-68.2,47.2-98.34,15.37-21.89,33.57-41.38,54.5-57.9,19.81-15.63,40.72-29.94,64.21-39.77,15.93-6.66,31.65-14.22,48.15-19.04,26.46-7.74,53.51-13.48,81.26-14.41,19.6-.66,39.26-1.96,58.8-1.09,23.55,1.05,46.8,5.25,69.9,10.24,26.24,5.68,50.59,15.91,74.75,27.28,24,11.3,45.62,26.05,65.48,43.21,13.99,12.09,26.86,25.39,38.66,39.99,22.72,28.11,39.7,59.21,51.2,93,7.35,21.57,11.84,44.3,15.62,66.85,3,17.93,4.32,36.39,4,54.57-.61,34.63-6.17,68.62-15.32,102.2-7.28,26.7-19.62,50.83-33.22,74.36-12.67,21.9-28.97,41.34-47.1,59.11-27.85,27.31-59.93,48.65-95.59,63.89-29.96,12.8-61.4,21.6-93.86,26.17-27.75,3.9-55.64,5.57-83.58,3.82-35.29-2.22-69.65-9.29-103.31-20.5-27.37-9.12-52.99-21.7-76.71-37.65-16.17-10.87-31.04-23.92-45.44-37.14-18.21-16.71-33.07-36.54-45.74-57.64-16.99-28.31-29.96-58.38-37.27-90.92-6.59-29.35-10.92-58.8-10.32-88.35Zm342.9,203.97c10.91-.94,21.88-1.41,32.71-2.92,37.75-5.28,71.56-19.62,100.09-45.27,22.4-20.14,39.39-44.37,49.4-72.7,11.83-33.46,17.49-67.93,13.75-103.77-3.13-30.04-9.12-59.03-23.96-85.63-3.87-6.94-8.73-13.33-12.82-20.16-10.65-17.78-25.86-31.26-42.36-43.01-31.39-22.37-67.11-33.35-105.33-35.37-31.87-1.69-62.91,3.13-92.49,15.96-24.42,10.59-46.19,24.94-63.81,44.94-21.89,24.85-36.8,53.39-44.26,85.87-6.08,26.46-7.79,53.08-5.69,80.08,1.61,20.68,6.2,40.76,13.16,60.14,7.92,22.04,20.39,41.54,35.74,59.4,16.16,18.8,35.87,32.75,57.82,43.24,27.65,13.22,57.28,18.86,88.05,19.19Z"
        />
        <path
          fill="white"
          className="cls-1"
          d="m622.08,137.19c-34.01,28.23-67.7,56.2-101.25,84.04-10-11.82-18.92-23.76-29.26-34.3-27.56-28.12-61.4-44.99-99.75-53.07-22.62-4.77-45.52-5.98-68.51-3.99-24.38,2.1-47.48,8.71-69.85,18.92-31.8,14.51-55.62,37.71-73.4,66.83-13.56,22.2-21.82,46.93-26.11,72.8-2.89,17.43-4.63,34.95-3.85,52.62,1.8,40.89,11.53,79.61,33.76,114.35,15.68,24.51,36.87,43.46,62.5,57.39,22.86,12.43,47.65,18.12,73.19,21.29,30.5,3.78,60.19-.44,89.77-7.93,21.03-5.33,39.36-15.29,57.2-27.25,21.73-14.57,38.08-33.84,53.49-56.19,33.62,28.04,67.36,56.18,102.03,85.09-6.52,8.8-12.63,17.86-19.57,26.23-16.66,20.08-35.58,38.06-57.02,52.74-22.75,15.57-46.99,28.62-73.52,37.43-30.46,10.11-61.29,17.24-93.32,19.85-50.24,4.1-99.57.26-147.85-14.62-29.65-9.14-57.72-21.48-84.03-38.35-24.03-15.4-44.96-33.9-64.38-54.56-18.15-19.32-32.61-41.32-44.52-64.59-14.23-27.81-25.87-57.18-30.93-88.17-4.43-27.15-8.09-54.64-6.53-82.35.73-12.91,2.01-25.82,3.57-38.66,2.12-17.48,4.96-34.82,10.54-51.66,11.13-33.61,25.16-65.67,45.73-94.83,17.43-24.71,37.82-46.61,61.37-65.15,31.87-25.1,67.7-43.55,106.65-55.07,26.11-7.72,52.82-13.37,80.17-14.5,20.25-.84,40.6-2.23,60.78-1.06,20.14,1.17,40.22,4.63,60.15,8.12,38.52,6.76,73.92,21.87,106.49,43.18,15.5,10.14,30.15,21.51,43.59,34.6,13.17,12.83,24.49,27.05,35.98,41.21,2.27,2.8,4.14,5.93,6.69,9.63Z"
        />
        <path
          fill="white"
          className="cls-1"
          d="m3486.37,10.64h152.94c.28,2.39.66,4.3.71,6.22,1.15,48.4-5.42,95.66-23.22,140.79-12.02,30.49-29.28,58.21-50.17,83.55-9.29,11.27-18.63,22.51-28.35,33.41-18.8,21.07-38,41.79-56.83,62.83-19.92,22.24-40.71,43.77-57.52,68.63-14.98,22.15-25.2,45.99-26.54,73.07-.48,9.62-1.24,19.23-1.26,28.85-.13,44.99-.06,89.99-.06,134.98v11.54h-152.42v-12.02c0-47.33-.07-94.65.02-141.98.06-30.87-5.84-60.18-22.79-86.46-6.65-10.32-13.71-20.49-21.6-29.86-14.74-17.51-30.11-34.49-45.45-51.48-11.57-12.82-23.77-25.07-35.19-38.02-14.28-16.19-28.09-32.8-42.01-49.3-5.14-6.09-10.26-12.23-14.94-18.68-14.3-19.71-26.96-40.51-36.36-62.96-12.14-28.94-20.14-59.17-22.94-90.51-1.54-17.2-1.89-34.51-2.71-51.77-.16-3.27-.02-6.56-.02-9.59,8.15-2.17,146.18-2.57,158.42-.49,0,3.98.09,8.22-.01,12.45-.57,24.35,1.88,48.53,5.84,72.43,3.28,19.8,12.14,37.82,22.14,55.28,11.49,20.06,26.75,37.2,41.36,54.79,12.48,15.03,26.62,28.68,39.68,43.25,18.4,20.54,36.42,41.42,51.77,64.42.7,1.05,1.63,1.93,3.01,3.54,2.13-2.63,4.1-4.75,5.72-7.11,17.94-26.12,38.94-49.62,60.58-72.69,16.12-17.18,31.11-35.42,46.5-53.28,16.21-18.8,29.38-39.65,37.87-62.91,6.83-18.71,10.65-38.4,11.72-58.43.92-17.22,1.42-34.46,2.14-52.5Z"
        />
        <path
          fill="white"
          className="cls-1"
          d="m2386.11,654.69h-155.28c-1.19-28.08-.35-56.24-.5-84.38-.16-28.66-.04-57.32-.04-85.98V142.09c-12.83-.09-25.03-.2-37.22-.24-12.33-.04-24.66,0-36.99,0h-150.05c4.15-44.33,8.19-87.42,12.28-131.09h581.62c4.63,42.97,8.38,86.13,12.33,130.82-13.13.09-25.62.22-38.12.26-12.33.04-24.66,0-36.99,0h-151.03v512.84Z"
        />
      </g>
    </svg>
  );
};
