import axios from 'axios';
import { config } from 'config';
import { Alert } from 'types';

export const getAlerts = async () => {
  try {
    const resp = await axios.post(`${config.monitoringIngestionUrl}/getAlerts`);
    if (resp) {
      return resp.data as Alert[];
    }
  } catch (error) {
    return null;
  }
};
