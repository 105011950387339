import React, { useState } from 'react';
import { Button, FormControl, MenuItem, Menu, Box } from '@mui/material';
import { config } from 'config';

import PublicIcon from '@mui/icons-material/Public';
import { Airport, City, Country } from 'types';

interface CountryDropdownProps {
  setSelectedCountry: React.Dispatch<React.SetStateAction<Country | null>>;
  setSelectedCity: React.Dispatch<React.SetStateAction<City | null>>;
  setSelectedAirport: React.Dispatch<React.SetStateAction<Airport | null>>;
  selectedCountry: Country | null;
  locations: Country[];
}

export const CountryDropdown: React.FC<CountryDropdownProps> = ({
  setSelectedCountry,
  setSelectedCity,
  setSelectedAirport,
  selectedCountry,
  locations,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (country: Country | null) => {
    handleClose();
    setSelectedCountry(country);
    setSelectedCity(null);
    setSelectedAirport(null);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <Button
        onClick={handleClick}
        variant="contained"
        style={{
          padding: '5px',
          marginRight: '10px',
          width: '130px',
          backgroundColor: config.colors.blue,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '12px',
          display: 'flex',
        }}
      >
        <Box flex={0.25} display="flex" alignItems="center" justifyContent="center">
          <PublicIcon style={{ flexShrink: 0 }} />
        </Box>
        <Box
          flex={0.75}
          component="span"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {selectedCountry ? selectedCountry.name : 'Country'}
        </Box>
      </Button>

      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {locations.map((location) => {
          return (
            <MenuItem key={location.country_id} onClick={() => handleMenuItemClick(location)}>
              {location.name}
            </MenuItem>
          );
        })}
        {selectedCountry && (
          <MenuItem
            sx={{ color: 'red' }}
            key="null-option"
            onClick={() => handleMenuItemClick(null)}
          >
            Remove filter
          </MenuItem>
        )}
      </Menu>
    </FormControl>
  );
};
