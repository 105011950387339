import axios from 'axios';
import { config } from 'config';
import { Device, DeviceStatus } from 'types';

export const getDeviceStatus = async () => {
  try {
    const resp = await axios.post(`${config.monitoringIngestionUrl}/getDeviceStatuses`);
    if (resp) {
      return resp.data as DeviceStatus[];
    }
  } catch (error) {
    return null;
  }
};

export const deleteDevice = async (deviceId: string) => {
  try {
    await axios.post(`${config.monitoringIngestionUrl}/delete_device`, { deviceId });
    return true;
  } catch (error) {
    return null;
  }
};

export const getADevice = async (deviceId: string) => {
  try {
    const resp = await axios.post(`${config.monitoringIngestionUrl}/get_a_device`, { deviceId });
    return resp.data as Device;
  } catch (error) {
    return null;
  }
};
export const addDevice = async (device: Device) => {
  try {
    await axios.post(`${config.monitoringIngestionUrl}/add_device`, { ...device });
    return true;
  } catch (error) {
    return null;
  }
};
