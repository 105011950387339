import React, { useEffect } from 'react';
import { Button, Box } from '@mui/material';
import { config } from 'config';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CreateIcon from '@mui/icons-material/Create';
import { CountryDropdown } from './CountryDropdown';
import { CityDropdown } from './CityDropdown';
import { AirportDropdown } from './AirportDropdown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Country, City, Airport, DisplayLocation, DeviceStatus, Device } from 'types';
import { getCitiesByCountryId } from 'utils/utils';
import { useNavigate } from 'react-router-dom';
import { NumberIcon } from './NumberIcon';

interface LocationsButtonsProps {
  locations: Country[];
  setFilteredLocations: React.Dispatch<React.SetStateAction<DisplayLocation>>;
  filteredLocations: DisplayLocation;
  setSelectedCountry: React.Dispatch<React.SetStateAction<Country | null>>;
  selectedCountry: Country | null;
  setShowMap: React.Dispatch<React.SetStateAction<boolean>>;
  deviceStatus: DeviceStatus[] | null;
  setSelectedCity: React.Dispatch<React.SetStateAction<City | null>>;
  setSelectedAirport: React.Dispatch<React.SetStateAction<Airport | null>>;
  selectedCity: City | null;
  selectedAirport: Airport | null;
  devices: Device[] | null;
  showMap: boolean;
}

export const LocationsButtons: React.FC<LocationsButtonsProps> = ({
  locations,
  setFilteredLocations,
  setSelectedCountry,
  selectedCountry,
  setShowMap,
  showMap,
  deviceStatus,
  setSelectedCity,
  setSelectedAirport,
  selectedCity,
  selectedAirport,
  devices,
}) => {
  // const [selectedTerminal, setSelectedTerminal] = useState<Terminal | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedCountry) {
      if (selectedCity) {
        if (selectedAirport) {
          const terminals = selectedAirport?.terminals;
          console.log('terminals:', terminals);
          if (terminals) {
            setFilteredLocations(terminals);
          }
        } else {
          const airports = selectedCity?.airports;

          if (airports) {
            setFilteredLocations(airports);
          }
        }
      } else {
        const cities = getCitiesByCountryId(locations, selectedCountry.country_id);
        console.log('cities:', cities);
        setFilteredLocations(cities);
      }
    } else {
      setFilteredLocations(null);
    }
  }, [selectedCountry, selectedCity, locations, selectedAirport]);

  return (
    <>
      <Box display="flex" justifyContent="flex-start" alignItems="flex-end" flex={1}>
        <CountryDropdown
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          setSelectedAirport={setSelectedAirport}
          setSelectedCity={setSelectedCity}
          locations={locations}
        />
        <CityDropdown
          setSelectedCity={setSelectedCity}
          setSelectedAirport={setSelectedAirport}
          selectedCountry={selectedCountry}
          selectedCity={selectedCity}
          locations={locations}
        />
        <AirportDropdown
          setSelectedCity={setSelectedCity}
          selectedCountry={selectedCountry}
          selectedCity={selectedCity}
          setSelectedAirport={setSelectedAirport}
          selectedAirport={selectedAirport}
          locations={locations}
        />
      </Box>
      <Box display="flex" alignItems="flex-end" justifyContent="flex-end" flex={1}>
        <Button
          onClick={() => navigate('/location-devices?id=active')}
          startIcon={
            <NumberIcon
              value={
                deviceStatus &&
                deviceStatus.filter(
                  (dev) => dev.active && devices?.find((dvc) => dvc.device_id === dev.deviceId),
                ).length
              }
            />
          }
          variant="contained"
          style={{ marginRight: '10px', width: '130px', backgroundColor: config.colors.green }}
        >
          Active
        </Button>
        <Button
          onClick={() => navigate('/location-devices?id=offline')}
          startIcon={
            <NumberIcon
              value={
                deviceStatus &&
                deviceStatus.filter(
                  (dev) => !dev.active && devices?.find((dvc) => dvc.device_id === dev.deviceId),
                ).length
              }
            />
          }
          variant="contained"
          color="primary"
          style={{ marginRight: '10px', width: '130px', backgroundColor: config.colors.orange }}
        >
          Offline
        </Button>
        <Box display="flex" flexDirection="column" width="130px">
          <Button
            onClick={() => navigate('/create-location')}
            startIcon={<CreateIcon />}
            variant="contained"
            color="primary"
            style={{
              marginRight: '10px',
              width: '140px',
              backgroundColor: config.colors.blue,
            }}
          >
            Create
          </Button>
          <Button
            onClick={() => setShowMap(!showMap)}
            startIcon={showMap ? <VisibilityOffIcon /> : <VisibilityIcon />}
            variant="outlined"
            color="primary"
            style={{ marginRight: '10px', width: '140px', marginTop: '10px' }}
          >
            {showMap ? 'Hide Map' : 'Show Map'}
          </Button>
        </Box>
      </Box>
    </>
  );
};
