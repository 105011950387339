import axios from 'axios';
import { config } from 'config';

export const apiTest = async (accessToken: string) => {
  try {
    const resp = await axios.post(`${config.endpointUrl}/apiTest`, { accessToken });
    if (resp.status !== 200) {
      throw new Error('Not authorized');
    }
    return resp.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};
