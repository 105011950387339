import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { config } from 'config';

interface StatusCardProps {
  number: number;
  status: string;
  lastUpdated: string;
  onClick: () => void;
}

export const StatusCard: React.FC<StatusCardProps> = ({ number, status, lastUpdated, onClick }) => {
  const offlineDeployments = 'Offline Deployments';
  return (
    <Paper
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        paddingRight: '10px',
        marginBottom: '20px',
        borderLeftWidth: '8px',
        borderLeftStyle: 'solid',
        borderLeftColor: status === offlineDeployments ? config.colors.orange : config.colors.green,
        height: '150px',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Box
        height="100%"
        bgcolor={config.colors.lightGray}
        flex={0.25}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography fontWeight={500} variant="h2" color={config.colors.darkGray}>
          {number}
        </Typography>
      </Box>
      <Box
        paddingLeft="20px"
        flex={0.65}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-around"
      >
        <Typography variant="subtitle1">Device Status</Typography>
        <Typography
          fontWeight={500}
          variant="h5"
          color={status === offlineDeployments ? config.colors.orange : config.colors.green}
        >
          {status}
        </Typography>
        <Typography variant="caption">{lastUpdated}</Typography>
      </Box>
    </Paper>
  );
};
