import { Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { DisplayLocation, DisplayLocationItem } from 'types';

interface CreateLocationFormDropdownProps {
  locations: DisplayLocation;
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setLocationImg: React.Dispatch<React.SetStateAction<string>>;
  setLocationLat: React.Dispatch<React.SetStateAction<number | null>>;
  setLocationLng: React.Dispatch<React.SetStateAction<number | null>>;
  setLocationName: React.Dispatch<React.SetStateAction<string>>;
  setLocationId?: React.Dispatch<React.SetStateAction<number | null>>;
}

export const CreateLocationFormDropdown: React.FC<CreateLocationFormDropdownProps> = ({
  locations,
  anchorEl,
  setAnchorEl,
  setLocationName,
  setLocationImg,
  setLocationLat,
  setLocationLng,
  setLocationId,
}) => {
  const handleMenuItemClick = (location: DisplayLocationItem) => {
    console.log('locatiosssn:', location);
    console.log(location);
    setLocationName(location?.name as string);
    setLocationImg(location?.image as string);
    setLocationLat(location?.lat as number);
    setLocationLng(location?.lng as number);
    setAnchorEl(null);

    if (setLocationId && location) {
      if ('country_id' in location) {
        setLocationId(location.country_id);
      }
      if ('city_id' in location) {
        setLocationId(location.city_id);
      }
      if ('airport_id' in location) {
        setLocationId(location.airport_id);
      }
      if ('terminal_id' in location) {
        setLocationId(location.terminal_id);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
      {locations && locations.length > 0 ? (
        <>
          {locations.map((loc, i) => {
            return (
              <MenuItem key={i} onClick={() => handleMenuItemClick(loc)}>
                {loc.name}
              </MenuItem>
            );
          })}
        </>
      ) : (
        <Typography>No cities found within </Typography>
      )}
    </Menu>
  );
};
