import { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { config } from 'config';

type IUpdateEmailModal = {
  existingEmail: string;
  openUpdateEmail: boolean;
  setOpenUpdateEmail: (openUpdateEmail: boolean) => void;
  setUpdateEmailSuccess: (updateEmailSuccess: boolean) => void;
};

export const UpdateEmailModal = (props: IUpdateEmailModal): JSX.Element => {
  const [currentEmail, setCurrentEmail] = useState<string>('');
  const [newEmail, setNewEmail] = useState<string>('');

  const [error, setError] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    props.setUpdateEmailSuccess(false);
  }, []);

  const onCloseDialog = (_: unknown, reason: string) => {
    if (reason === 'backdropClick') return;
    handleClose();
  };

  const handleClose = () => {
    props.setOpenUpdateEmail(false);
  };

  const handleUpdateEmail = async () => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      if (!accessToken) throw new Error('No access token');
      setIsDisabled(true);
      const res = await fetch(`${config.endpointUrl}/updateUserEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({ accessToken, currentEmail, newEmail }),
      });
      const data = await res.json();
      if (data.error) throw new Error(data.error);
      props.setUpdateEmailSuccess(true);
      handleClose();
    } catch (err: any) {
      console.log(err);
      setError(true);
      setErrorMessage(err.message);
      setIsDisabled(false);
    }
  };

  return (
    <div>
      <Dialog
        open={props.openUpdateEmail}
        onClose={onCloseDialog}
        className="update-email-dialog-wrapper"
      >
        <DialogTitle className="alert-dialog-title">Update Email</DialogTitle>
        <DialogContent>
          <DialogContentText className="alert-dialog-description">
            <p>
              We will send you a new email to your existing email address, {props.existingEmail} to
              confirm this change. If you don't have access to this email, please contact support
            </p>
            <p>You need to verify your account once your email has been changed</p>
          </DialogContentText>
          <TextField
            className="current-email-input input"
            label="Current Email"
            variant="filled"
            value={currentEmail}
            error={error}
            helperText={errorMessage}
            onInput={(e: any) => setCurrentEmail(e.target.value)}
            disabled={isDisabled}
          />
          <TextField
            className="new-email-input input"
            label="New Email"
            variant="filled"
            value={newEmail}
            error={error}
            onInput={(e: any) => setNewEmail(e.target.value)}
            disabled={isDisabled}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button
            onClick={handleUpdateEmail}
            color="error"
            variant="contained"
            disabled={isDisabled}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
