// ScrollingGrid.tsx

import React from 'react';
import { Grid } from '@mui/material';
import { LocationItem } from './LocationItem';
import { Country, DeviceStatus, DisplayLocation, Device, City, Airport } from 'types';

interface LocationsGridProps {
  locations: Exclude<DisplayLocation, null>;
  deviceStatus: DeviceStatus[] | null;
  devices: Device[] | null;
  selectedCountry: Country | null;
  selectedCity: City | null;
  selectedAirport: Airport | null;
}

export const LocationsGrid: React.FC<LocationsGridProps> = ({
  locations,
  deviceStatus,
  selectedCountry,
  devices,
  selectedCity,
  selectedAirport,
}) => {
  return (
    <div
      style={{
        overflowY: 'auto',
        height: 'calc(100vh - 295px)',
        width: '100%',
      }}
    >
      <Grid container spacing={3}>
        {/* Example of 12 items, but you can add more */}
        {locations.map((location, index) => (
          <Grid item xs={4} key={index} mt={'1px'}>
            <LocationItem
              selectedAirport={selectedAirport}
              selectedCity={selectedCity}
              deviceStatus={deviceStatus}
              devices={devices}
              location={location}
              selectedCountry={selectedCountry}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
