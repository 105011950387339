import React from 'react';
import { Box, Typography, Button, Paper, Divider } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Notification } from './Notification';
import { config } from 'config';
import { Alert, Device } from 'types';

interface LatestNotificationProps {
  alerts: Alert[] | null;
  devices: Device[] | null;
}

export const LatestNotification: React.FC<LatestNotificationProps> = ({ alerts, devices }) => {
  return (
    <Paper style={{ padding: '20px', height: 'calc(100vh - 225px)' }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5" fontWeight={500} color={config.colors.darkGray}>
          Latest Notifications
        </Typography>
        <Button>
          <FilterAltIcon />
          <Typography variant="button" color={config.colors.primaryColor}>
            Filters
          </Typography>
        </Button>
      </Box>
      <Divider style={{ margin: '10px 0' }} />

      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        marginRight="10%"
        height="calc(100vh - 265px)"
        overflow="scroll"
      >
        <Box display="flex" flexDirection="column" justifyContent="space-between" marginTop="20px">
          {alerts && alerts.length > 0 ? (
            alerts
              .sort((a, b) => b.last_online - a.last_online)
              .map((alert) => {
                const device = devices?.find((dvc) => dvc.device_id === alert.deviceId);
                if (!device) return null;
                return <Notification alert={alert} device={device} />;
              })
          ) : (
            <Typography>No alerts</Typography>
          )}
        </Box>
      </Box>
    </Paper>
  );
};
