import { Typography, Grid } from '@mui/material';
import { DeviceStatus, LatestNotification, Loader, Navbar } from 'components';
import { config } from 'config';
import { useAuth } from 'context';
import { useEffect, useState } from 'react';
import { Alert, Device, DeviceStatus as DeviceStatusType } from 'types';
import { getAlerts, getDeviceStatus } from 'utils/apiRequests';
import { getDevices } from 'utils/apiRequests/locations';

export const Dashboard = () => {
  const [deviceStatus, setDeviceStatus] = useState<DeviceStatusType[] | null>(null);
  const [alerts, setAlerts] = useState<Alert[] | null>(null);
  const [devices, setDevices] = useState<Device[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastFetch, setLastFetch] = useState<number | null>(null);

  const { user } = useAuth();

  const getData = async () => {
    setIsLoading(true);
    const devicesResp = await getDevices();
    const deviceStatusResp = await getDeviceStatus();
    const alertsResp = await getAlerts();

    if (devicesResp) {
      if (!user?.isDeveloper) {
        setDevices(devicesResp.filter((dvc) => !dvc.test));
      } else {
        setDevices(devicesResp);
      }
    }

    if (deviceStatusResp) {
      setDeviceStatus(deviceStatusResp);
    }
    if (alertsResp) {
      setAlerts(alertsResp);
    }

    setLastFetch(new Date().getTime());

    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Navbar>
      <div
        style={{
          width: '100%',
          backgroundColor: config.colors.background,
          padding: '20px',
          height: '100%',
        }}
      >
        {/* Breadcrumbs */}
        <Typography mb="40px" color={config.colors.darkGray}>
          Dashboard / Device Overview
        </Typography>
        {isLoading && <Loader />}
        {/* Device Status */}
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <DeviceStatus lastFetch={lastFetch} devices={devices} deviceStatus={deviceStatus} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <LatestNotification devices={devices} alerts={alerts} />
          </Grid>
        </Grid>
      </div>
    </Navbar>
  );
};
