import { Box, Typography } from '@mui/material';
import React from 'react';

interface NumberIconProps {
  value: number | null;
}

export const NumberIcon: React.FC<NumberIconProps> = ({ value }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={20}
      height={20}
      border="1px solid white"
      borderRadius={50}
    >
      <Typography>{value ?? '?'}</Typography>
    </Box>
  );
};
