import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { config } from 'config';
import { useNavigate } from 'react-router-dom';

type IDeleteConfirm = {
  endpoint: string;
  idName: string;
  currentId?: string;
  openDelete: boolean;
  setOpenDelete: (onpenDeleteUser: boolean) => void;
  redirectUrl: string;
  dialogTitle: string;
  diglogDescription: string;
};

export const DeleteConfirm = (props: IDeleteConfirm): JSX.Element => {
  const navigate = useNavigate();

  const onCloseDialog = (_: unknown, reason: string) => {
    if (reason === 'backdropClick') return;
    handleClose();
  };

  const handleClose = () => {
    props.setOpenDelete(false);
  };

  const handleDeleteUser = async () => {
    props.setOpenDelete(false);
    const accessToken = localStorage.getItem('accessToken');
    try {
      if (!accessToken) return;

      const res = await fetch(config.endpointUrl + props.endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({ [props.idName]: props.currentId, accessToken }),
      });
      const data = await res.json();
      if (data.error) throw new Error(data.error);
      navigate(props.redirectUrl);
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <div>
      <Dialog open={props.openDelete} onClose={onCloseDialog} className="dialog-wrapper">
        <DialogTitle className="alert-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText className="alert-dialog-description">
            {props.diglogDescription}
            <br />
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button onClick={handleDeleteUser} color="error" variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
