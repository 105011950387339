// LocationPage.tsx

import React, { useEffect, useState } from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { config } from 'config';
import { Loader, LocationsButtons, LocationsGrid, LocationsMap, Navbar } from 'components';
import { Airport, City, Country, Device, DeviceStatus, DisplayLocation } from 'types';
import { getDevices, getLocations } from 'utils/apiRequests/locations';
import { getDeviceStatus } from 'utils/apiRequests';
import { useAuth } from 'context';

export const Locations: React.FC = () => {
  const [locations, setLocations] = useState<Country[] | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [filteredLocations, setFilteredLocations] = useState<DisplayLocation>(null);
  const [deviceStatus, setDeviceStatus] = useState<DeviceStatus[] | null>(null);
  const [devices, setDevices] = useState<Device[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showMap, setShowMap] = useState<boolean>(true);

  const [selectedCity, setSelectedCity] = useState<City | null>(null);
  const [selectedAirport, setSelectedAirport] = useState<Airport | null>(null);

  const { user } = useAuth();

  const getData = async () => {
    setIsLoading(true);
    const locationsResp = await getLocations();
    const deviceStatusResp = await getDeviceStatus();
    const devicesResp = await getDevices();

    if (devicesResp) {
      if (!user?.isDeveloper) {
        setDevices(devicesResp.filter((dvc) => !dvc.test));
      } else {
        setDevices(devicesResp);
      }
    }

    if (locationsResp) {
      setLocations(locationsResp);
    }
    if (deviceStatusResp) {
      setDeviceStatus(deviceStatusResp);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Navbar>
      <div
        style={{
          padding: '20px',
          width: '100%',
          height: '100%',
          backgroundColor: config.colors.background,
        }}
      >
        {isLoading && <Loader />}
        <Typography color={config.colors.darkGray}>Dashboard / Device Overview</Typography>

        {/* Buttons */}
        <Box display="flex" mb="20px">
          {locations && (
            <LocationsButtons
              setSelectedCity={setSelectedCity}
              selectedAirport={selectedAirport}
              selectedCity={selectedCity}
              setSelectedAirport={setSelectedAirport}
              filteredLocations={filteredLocations}
              setFilteredLocations={setFilteredLocations}
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
              setShowMap={setShowMap}
              showMap={showMap}
              locations={locations}
              deviceStatus={deviceStatus}
              devices={devices}
            />
          )}
        </Box>

        {/* Grid & Map */}
        <Grid container spacing={3}>
          <Grid item xs={showMap ? 6 : 12}>
            {locations && locations.length > 0 ? (
              <>
                {filteredLocations ? (
                  <LocationsGrid
                    selectedCity={selectedCity}
                    selectedAirport={selectedAirport}
                    devices={devices}
                    deviceStatus={deviceStatus}
                    locations={filteredLocations}
                    selectedCountry={selectedCountry}
                  />
                ) : (
                  <LocationsGrid
                    selectedCity={selectedCity}
                    selectedAirport={selectedAirport}
                    devices={devices}
                    deviceStatus={deviceStatus}
                    locations={locations}
                    selectedCountry={null}
                  />
                )}
              </>
            ) : (
              <Typography>No locations found.</Typography>
            )}
          </Grid>
          {showMap && (
            <Grid item xs={6}>
              <LocationsMap
                selectedCountry={selectedCountry}
                locations={filteredLocations ? filteredLocations : locations}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </Navbar>
  );
};
